import { Box, Typography, Skeleton } from "@mui/material";
import { PaymentActionSkeleton } from "Components/Dashboard/BalanceWithPaymentActions/MobileBalance/Components/PaymentActionSkeleton";

type BalanceSkeletonProps = {
  isError?: boolean;
};

export const MobileBalanceSkeleton = ({
  isError,
}: BalanceSkeletonProps = {}) => {
  const animation = isError ? false : "pulse";

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        {isError ? (
          <Typography color="error.main" height={48} textAlign="center">
            To nás velmi mrzí. Během načítání se objevil neočekávaný problém.
            Zkuste akci, prosím, později.
          </Typography>
        ) : (
          <Skeleton
            variant="text"
            width={200}
            height={48}
            sx={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
          />
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-around"
        width="100%"
        maxWidth="300px"
        gap={18}
      >
        <PaymentActionSkeleton animation={animation} />
        <PaymentActionSkeleton animation={animation} />
      </Box>
    </>
  );
};
