import { useQuery } from "@tanstack/react-query";
import { getCodeList } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const CODELIST_QUERY_KEY = "CODELIST";

export const useCodeListQuery = () =>
  useQuery({
    queryKey: [CODELIST_QUERY_KEY],
    queryFn: processResponse(() => getCodeList()),
    refetchOnWindowFocus: false,
  });
