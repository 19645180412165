import { Box } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  maxWidth?: number;
};

export const CarouselContainer = ({ children, maxWidth = 420 }: Props) => (
  <Box
    sx={{
      maxWidth,
      width: "100%",
      margin: "auto",
      overflow: "hidden",
      py: "0.75rem",
    }}
  >
    {children}
  </Box>
);
