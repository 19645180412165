import { UploadIdentificationCommandResultStatus } from "Api/Api";
import { ResourceDictionary } from "./Resources";
import zodTranslation from "zod-i18n-map/locales/cs/zod.json";
import { UploadIdentificationCommandResultStatus2 } from "Infrastructure/Api/Api";

export const CzechResourcesDictionary: ResourceDictionary = {
  Common: {
    AppName: "Moone",
    Amount: "Částka",
    Note: "Poznámka",
    Unknown: "Neznámý",
    ShowAll: "Zobrazit vše",
    ShowMore: "Zobrazit více",
    VariableSymbol: "Variabilní symbol",
    Required: "Povinné pole",
    InvalidIBAN: "Neplatný IBAN",
    CancelEdit: "Zrušit úpravy",
    Cancel: "Zrušit",
    Confirm: "Potvrdit",
    NiceDay: "Hezký den",
    Send: "Odeslat",
    Continue: "Pokračovat",
    FillMissing: "Vyplňte všechny povinné údaje",
    FirstName: "Jméno",
    LastName: "Příjmení",
    Gender: "Pohlaví",
    PersonalNumber: "Rodné číslo",
    BirthPlace: "Místo narození",
    Nationality: "Státní příslušnost",
    PostalCode: "PSČ",
    Number: "Číslo",
    OrientationNumber: "Číslo orientační",
    DescriptionNumber: "Číslo popisné",
    City: "Město",
    Type: "Typ",
    IdentityDocument: "Doklad totožnosti",
    Contact: "Kontakt",
    Phone: "Telefon",
    Delete: "Odstranit",
    Edit: "Upravit",
    BirthDate: "Datum narození",
    AccountNumber: "Číslo účtu",
    TransactionPaymentCode: "Kód transakce pro platbu",
    IBAN: "IBAN",
  },
  CodeLists: {
    IdentificationList: {
      IDCard: "Občanský průkaz",
      Passport: "Pas",
      Other: "Jiný",
    },
  },
  Errors: {
    RequestError: "Během požadavku nastala chyba",
    ApplicationError: {
      Title: "Nastala chyba",
      Home: "Domů",
    },
    Error404: {
      Subtitle: "Stránka nebyla nalezena",
      Home: "Domů",
    },
    Error403: {
      Subtitle: "K zobrazení této stránky nemáte přístupová práva",
    },
  },
  SignIn: {
    Error: {
      General: "Nastala chyba",
      InvalidLogin: "Špatné heslo nebo e-mail",
      InvalidCredentials: "Špatné heslo nebo e-mail",
      ApplicationVerification: "Špatné heslo nebo e-mail",
      PhoneNumberNotFound: "Telefonní číslo nebylo nalezeno",
      SmsSendingFailed: "Zlyhalo posílání SMS ",
      InvalidToken: "Navalidní token",
      AccountNotFound: "Špatné heslo nebo e-mail",
      AccountIsDeactivated:
        "Účet byl deaktivován. Jestli chcete účet obnovit, kontaktujte podporu.",
    },
  },
  SignUp: {
    Error: {
      General: "Nastala chyba",
      UserExists: "Účet již existuje",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
  },
  Validation: {
    Required: "Vyplňte pole",
    InvalidEmail: "E-mail je nevalidní",
  },
  Menu: {
    MainMenuItems: {
      Overview: "Přehled",
      InitiatePayment: "Obchodník",
      History: "Historie",
      Accounts: "Účty",
      Settings: "Více",
    },
    BottomMenuItems: {
      Overview: "Přehled",
      History: "Historie",
      ActionButton: "Zaplatit",
      Accounts: "Účty",
      Settings: "Více",
    },
  },
  Onboarding: {
    ProcessTitle: "Informace o Vás",
    AdditionalInformationTitle: "Doplňující informace",
    LicenseSelectionTitle: "Vítejte v Legi.one",
    LicensePaymentTitle: "Platba licence",
    BankAccountConnectionTitle: "Připojení účtu",
    BankAccountVerificationTitle: "Ověření účtu",
    PolicicallyExposed: {
      Title: "Jsem politicky exponovaná osoba",
      Description: `Politicky exponovaná osoba (PEP) je osoba, která zastává nebo v nedávné minulosti zastával významnou veřejnou funkci, jako například prezident, premiér, ministr, velvyslanec, soudce nejvyššího soudu, vyšší armádní důstojník nebo člen parlamentu. K politicky exponovaným osobám se mohou řadit také jejich blízcí rodinní příslušníci nebo blízcí spolupracovníci.
Zákonnou definici PEP naleznete v ust. § 4 odst. 5 zákona č. 253/2008 Sb., o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu.`,
      Alert:
        "Politicky exponovaným osobám bohužel zatím nemůžeme službu poskytnout.",
    },
    Steps: {
      Documents: {
        LegalInformationBlock1:
          "V souladu se zákonem č. 253/2008 Sb., o některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu (AML zákon), jsme povinni provést Vaši identifikaci. Vaše osobní údaje budou zpracovávány dle Nařízení (EU) 2016/679 (GDPR) a uchovávány po dobu stanovenou zákonem. Údaje budou použity pouze k plnění zákonných povinností.",
        LegalInformationBlock2:
          "Za tímto účelem potřebujeme skeny Vašeho dokladu.",
        Processing: "Zpracováváme údaje. Chvilinku strpení.",
        ProcessingFailed: "Nepodařilo se zpracovat údaje z občanského průkazu",
        FirstDocumentTitle: "Občanský průkaz",
        SecondDocumentTitle: "Druhý doklad",
        SecondDocumentDescription:
          "Druhým dokladem může být přední strana řidičského průkazu nebo cestovní pas.",
        Forms: {
          DocumentTitle: "Nahrát sken dokladu",
          DocumentDescription: "Klikněte zde pro nahrání nebo vyfocení",
          FrontSide: "Přední strana občanského průkazu",
          BackSide: "Zadní strana občanského průkazu",
        },
        Validations: {
          ForegroundMissing: "Nahrajte prosím přední stranu občanského průkazu",
          BackgroundMissing: "Nahrajte prosím zadní stranu občanského průkazu",
          SecondForegroundMissing:
            "Nahrajte prosím přední stranu druhého dokladu",
        },
      },
      PersonalInformation: {
        Title: "Z nahraných dokladů jsme vyčetli následující údaje:",
        DataConfirmation: "Potvrzuji správnost údajů",
        FormGroups: {
          PersonalInformation: "Základní údaje",
          Address: "Adresa",
          IdentityDocument: "Doklad totožnosti",
        },
        DocumentValidity: {
          Generic: "Platnost dokladu",
          From: "Platnost dokladu od",
          To: "Platnost dokladu do",
        },
        DocumentIssued: "Doklad vydal",
        StreetAddress: "Ulice a č.p.",
        ConfirmDataRequest: "Musíte potvrdit správnost údajů",
      },
      FinalStep: {
        SubmitButton: "Dokončit",
      },
    },
    BusinessOnboarding: {
      Steps: {
        OwnerModal: {
          Title: "Informace o skutečném majiteli",
        },
        AdditionalInformation: {
          Title: "Doplňující informace",
          CheckboxTitle: "Jsem skutečný majitel",
          CheckboxDescription:
            "Skutečným majitelem je každá fyzická osoba, která v konečném důsledku tzv. vlastní nebo kontroluje právnickou osobu nebo právní uspořádání",
          OwnersTitle: "Další skuteční majitelé",
          AddOwner: "Přidat skutečného majitele",
          AddNextOwner: "Přidat dalšího skutečného majitele",
        },
        AccountValidation: {
          Description:
            "Nyní si připojte Váš bankovní účet, na který Vám budou chodit platby zákazníků.",
          PaymentNote: "Vytvoření účtu ZNPAy a.s. {{name}}",
        },
      },
    },
  },
  Transactions: {
    Title: "Transakce",
    History: "Historie",
    Payment: {
      Incoming: "Příchozí platba",
      Outgoing: "Odchozí platba",
    },
    Type: {
      Buy: "Nákup",
      Sell: "Prodej",
      Withdrawal: "Výběr",
      LeadReward: "Odměna za doporučení",
      LeadRewardConsumer: "Odměna za registraci",
      Voucher: {
        GiftCard: "Dárková karta",
        SignUpSubsidy: "Dárek k registraci",
      },
      Coinback: "Coinback",
    },
    ErrorPayment: {
      Title: "Nastala chyba",
      Description: "Je nám to líto, ale během platby došlo k problému.",
    },
    SuccessPayment: {
      Title: "Úspěšně zaplaceno!",
      DescriptionFirstPart: "Peníze byly úspěšně odeslány",
      DescriptionSecondPart: "na obchodníkův účet.",
    },
    Anonymous: {
      DownloadReceipt: "Stáhnout účtenku do zařízení",
      FillEmail: "Zadejte e-mail, abychom Vám mohli zaslat účtenku.",
    },
    Status: {
      Completed: "Provedeno",
      PendingTrade: "Zpracovává se",
      PendingPayout: "Čeká na potvrzení eshopem",
      WithdrawRequested: "Probíhá výběr",
      Withdrawn: "Provedeno",
    },
    TransactionStatus: {
      Cancelled: "Zrušená",
      Fail: "Selhala",
      Initiated: "Čeká na zaplacení",
      Processing: "Probíhá zpracování",
      Success: "Úspěšně přijata",
    },
    InstructionStatus: {
      AcceptedCustomerProfile: "Platba schválena",
      AcceptedSettlementCompleted: "Platba dokončena",
      AcceptedTechnicalValidation: "Přijato pro zpracování",
      None: "Neznámý",
      Rejected: "Platba zamítnuta",
      AcceptedWithChange: "Platba byla přijata, ale došlo ke změně údajů.",
    },
    InstructionStatusDescription: {
      AcceptedCustomerProfile:
        "Vaše platba byla úspěšně schválena a čeká na další kroky.",
      AcceptedSettlementCompleted:
        "Vaše platba byla úspěšně dokončena a peníze byly převedeny.",
      AcceptedTechnicalValidation:
        "Vaše platba byla přijata a je připravena ke zpracování.",
      None: "Stav platby není známý. Prosíme, kontaktujte nás pro více informací.",
      Rejected:
        "Vaše platba byla zamítnuta. Může to být kvůli nesprávným údajům nebo nedostatečným prostředkům.",
      AcceptedWithChange:
        "Vaše platba byla přijata, ale došlo ke změně údajů (např. datum nebo informace o platbě nebyly zaslány). Prosím, zkontrolujte detaily transakce.",
    },
    BankTransferInfo:
      "Bankovní převod může trvat až 2 pracovní dny, záleží na rychlosti banky. Po připsání bude částka směněna dle aktuálního tržního kurzu, který může být rozdílný oproti kurzu v době odeslání platby.",
  },
  SetPassword: {
    Error: {
      General: "Nastala chyba.",
      TokenNotFound: "Token je navalidní",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
  },
  BankIDVerification: {
    Error: {
      General:
        "Autentifikace přes BankID se nezdařila. Zkuste to prosím znovu.",
      BankIDUserInfoError: "Nepodařilo se získat data z BankID.",
      VerificationAlreadyExists: "Uživatel z BankID byl již ověřen.",
    },
  },
  Voucher: {
    ApplyError: {
      General: "Nastala chyba",
      NotFound:
        "Zadaný kód není správný. Zkontrolujte, zda je správně opsaný. Pokud problémy přetrvávají, kontaktujte nás.",
      Applied: "Kupon byl použit",
      UserAccountNotFound: "Uživatel nenalezen",
      UserNotFound: "Uživatel nenalezen",
      UserIsWaitingForEmailVerification: "Uživatel nenalezen",
      InvalidRecaptcha:
        "Recaptcha není validní. Refreshnite aplikaci a zkuste to znovu.",
    },
  },
  Balance: {
    Title: "Přehled",
    BalanceContent: {
      Title: "Hodnota účtu",
      PayButton: "Zaplatit",
      SendPaymentButton: "Poslat platbu",
    },
    NoAcount: {
      Title: "Začínáme",
      Description:
        "Abyste mohli začít platit Moonem, je potřeba připojit bankovní účet, ze kterého budete platby provádět. Nebojte, toto připojení je na pár kliknutí!",
      ActionButton: "Připojit účet",
    },
  },
  Banking: {
    Auth: {
      Title: "Připojit účet",
      Description: "Vyberte si banku, kterou chcete připojit.",
      BankList: {
        Title: "Nabídka bank",
        ComingSoon: "Již brzy",
      },
    },
  },
  BankNames: {
    AirBank: "Air Bank",
    CSAS: "Česká spořitelna",
    CSOB: "ČSOB",
    KB: "Komerční banka",
    UCB: "UniCredit Bank",
    Unknown: "Jiná banka",
  },
  Merchant: {
    Payment: {
      Title: "Dnešní tržba",
      InitiatePaymentButton: "Vyvolat platbu",
    },
    Initiate: {
      GenerateButton: "Generovat údaje",
      Form: {
        AccountSelectTitle: "Výběr podnikatelského účtu",
      },
    },
    InitiateDetail: {
      Title:
        'Zaplatit můžete oskenováním QR kódu, nebo vložením kódu transakce ve formuláři "Zaplatit".',
      PaymentStatus: "Status platby",
      TransactionPaymentCode: "Kód transakce pro platbu",
    },
  },
  Customer: {
    Pay: {
      Title: "Zaplatit",
      DescriptionText:
        "Zaplatit můžete i pomocí oskenování QR kódu protistrany.",
      PayButton: "Zaplatit",
      ChooseBankAccount: "Vyberte si účet, kterým chcete zaplatit",
      ChooseBank: "Vyberte si banku, kterou chcete zaplatit",
      AddNextBankAccount: "Přidat další účet",
    },
  },
  BankAccounts: {
    List: {
      Description:
        'Tyto bankovní účty jsou spárovány s Vaším účtem. Pokud chcete přidat další bankovní účet, klikněte na tlačítko "Spárovat bankovní účet."',
      NoAccounts: "Nemáte žádné spárované bankovní účty.",
      SaveOrder: "Uložit pořadí",
      EditOrder: "Upravit pořadí",
      AddAccount: "Spárovat bankovní účet",
    },
  },
  Form: {
    PersonalData: {
      Sex: {
        Female: "Žena",
        Male: "Muž",
      },
    },
    PartyType: {
      NaturalPerson: "Fyzická osoba",
      LegalEntity: "Právnická osoba",
      SelfEmployed: "Podnikající fyzická osoba (OSVČ)",
    },
    BusinessActivity: {
      RetailAndCommerce: "Maloobchod a obchod",
      FoodAndBeverageServices: "Stravovací služby",
      AccommodationAndHospitality: "Ubytování a pohostinství",
      BeautyAndPersonalCare: "Kosmetické a osobní služby",
      HealthcareAndPersonalCare: "Zdravotnické a pečovatelské služby",
      FinancialServicesAndInsurance: "Finanční služby a pojištění",
      EducationAndTraining: "Vzdělávání a školení",
      ConstructionAndMaintenance: "Stavebnictví a údržba",
      TransportationAndLogistics: "Doprava a logistika",
      ECommerceAndOnlineServices: "Elektronické obchodování a online služby",
      ArtsCultureAndEntertainment: "Umění, kultura a zábava",
      SportsAndRecreation: "Sport a rekreace",
      TechnologyAndITServices: "Technologie a IT služby",
      IndustrialProductionAndProcessing: "Průmyslová výroba a zpracování",
      RealEstateAndPropertyServices: "Nemovitosti a správa majetku",
      PersonalServices: "Osobní služby",
      LegalAndConsultingServices: "Právní a poradenské služby",
      Other: "Ostatní",
    },
    SourceOfIncome: {
      Employment: "Zaměstnání",
      SelfEmployment: "Samostatná výdělečná činnost",
      Other: "Jiné",
    },
    ExpectedMonthlyRevenue: {
      LessThan100K: "Méně než 100 000 Kč",
      From100KTo500K: "100 000 až 500 000 Kč",
      From500KTo1M: "500 000 až 1 000 000 Kč",
      Over1M: "Více než 1 000 000 Kč",
    },
    AverageTransactionValue: {
      LessThan1K: "Méně než 1 000 Kč",
      From1KTo10K: "1 000 až 10 000 Kč",
      From10KTo50K: "10 000 až 50 000 Kč",
      Over50K: "Více než 50 000 Kč",
    },
    RelationType: {
      BeneficialOwner: "Skutečný majitel",
      ManagingDirector: "Generální ředitel",
      BoardMember: "Člen představenstva",
      SupervisoryBoardMember: "Člen dozorčí rady",
      ProxyHolder: "Držitel plné moci",
      Partner: "Společník",
      GeneralPartner: "Komplementář",
      CooperativeChairman: "Předseda družstva",
      HOAChairman: "Předseda SVJ",
      HOACommitteeMember: "Člen výboru SVJ",
    },
    BankVerification: {
      NotVerified: "Neověřeno",
      Verified: "Ověřeno",
      InProgress: "Probíhá ověřování"
    },
    RiskProfile: {
      NotVerified: "Neověřeno",
      NoRisk: "Bez rizika",
      Risk: "Rizikový",
      Unacceptable: "Nepřijatelný"
    },
    IdentityVerification: {
      NotVerified: "Neověřeno",
      Verified: "Ověřeno",
      Processing: "Probíhá ověřování"
    },
    AdditionalInfo: {
      NotVerified: "Neověřeno",
      Verified: "Ověřeno",
      NeedsInvestigation: "Vyžaduje kontrolu"
    },
    UserStatus: {
      Unknown: "Neznámý",
      InvitedWaitingForConfirmation: "InvitedWaitingForConfirmation",
      Active: "Aktivní",
      WaitingForEmailConfirmation: "Čeká na potvrzení e-mailu",
      Deactivated: "Deaktivovaný",
      BlockedFinances: "BlockedFinances",
      Anonymous: "Anonymní",
      Onboarding: "Onboarding"
    },
    AddressType: {
      Permanent: "Trvalé bydliště",
      Mail: "Korespondenční",
      Headquarters: "Sídlo"
    },
  },
  FormSelectors: {
    BusinessForm: {
      Title: "Forma podnikání",
    },
  },
  ApiStatuses: {
    UnknownError: "Nastala neočekávaná chyba při zpracování požadavku",
    UploadIdentificationCommandResultStatus: {
      [UploadIdentificationCommandResultStatus.Success]: "Úspěšně nahráno",
      [UploadIdentificationCommandResultStatus.MissingFiles]:
        "Prosím, nahrajte všechny soubory",
    },
    UploadIdentificationCommandResultStatus2: {
      [UploadIdentificationCommandResultStatus2.Success]: "Úspěšně nahráno",
      [UploadIdentificationCommandResultStatus2.MissingFiles]:
        "Prosím, nahrajte všechny soubory",
    },
  },
  zod: zodTranslation,
};
