import { isValid } from "date-fns";
import { useCountryList } from "Hooks/Options/useCountryList";
import { useIdentificationCardList } from "Hooks/Options/useIdentificationCardList";
import { useGenderList } from "Hooks/Options/useGenderList";
import { useFormatLocale } from "Hooks/useFormatLocale";
import { UserDetailModel } from "Components/Onboarding/types";

export const usePersonalInformationValues = (
  values: Partial<UserDetailModel> = {},
) => {
  const format = useFormatLocale();

  const genders = useGenderList();
  const { countries } = useCountryList();
  const { identificationCards } = useIdentificationCardList();

  return [
    [
      {
        label: "Jméno",
        value: values.firstName,
      },
      {
        label: "Příjmení",
        value: values.lastName,
      },
      {
        label: "Rodné číslo",
        value: values.personalIdentificationNumber,
      },
      {
        label: "Místo narození",
        value: values.birthPlace,
      },
      {
        label: "Pohlaví",
        value: values.gender
          ? genders.find(c => c.code === values.gender)?.name
          : "",
      },
      {
        label: "Státní příslušnost",
        value: values.country
          ? countries.find(c => c.id === values.country)?.name
          : "",
      },
    ],
    [
      {
        label: "Ulice a č.p.",
        value: `${values.address?.street ?? ""} ${
          values.address?.descriptionNumber ?? ""
        } ${
          values.address?.orientationNumber
            ? `/${values.address?.orientationNumber}`
            : ""
        }`,
      },
      {
        label: "Město",
        value: values.address?.city,
      },
      {
        label: "PSČ",
        value: values.address?.postalCode,
      },
    ],
    [
      {
        label: "Typ",
        value: values.document?.type
          ? identificationCards.find(c => c.code === values.document?.type)
              ?.name
          : "",
      },
      {
        label: "Číslo",
        value: values.document?.number,
      },
      {
        label: "Platnost",
        value:
          values.document?.valid?.from && isValid(values.document?.valid?.from)
            ? format(values.document.valid.from) +
              " - " +
              format(values.document.valid.to ?? values.document.valid.from)
            : "",
      },
      {
        label: "Vydal",
        value: values.document?.issued,
      },
    ],
  ];
};
