import { SourceOfIncome } from "Infrastructure/Api/Api";
import { CodeListItem } from "Models/ICodeListDto";
import { useMemo } from "react";
import { Resources, useResource } from "Translations/Resources";

type ListItem = CodeListItem<SourceOfIncome>;
type Result = ListItem[];

export const useSourceOfIncomeList = () => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      Object.entries(SourceOfIncome).map<ListItem>(([key]) => ({
        code: key as SourceOfIncome,
        name: t(Resources.Form.SourceOfIncome[key as SourceOfIncome]),
      })),
    [t],
  );
};
