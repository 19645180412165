import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const CarouselTitle = ({ children }: Props) => (
  <Box mt="1rem">
    <Typography
      fontSize={"1.5rem"}
      textTransform={"uppercase"}
      fontWeight={"900"}
      lineHeight={"1.875rem"}
    >
      {children}
    </Typography>
  </Box>
); 