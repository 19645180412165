import { useQuery } from "@tanstack/react-query";
import { getUserAdminDetailUserID } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const ADMIN_USER_QUERY_KEY = ["ADMIN_USER"];

export const getAdminUserQueryKey = (userID: number) => [
  ADMIN_USER_QUERY_KEY,
  { userID },
];

export const useAdminUserDataQuery = (userID?: number) =>
  useQuery({
    queryKey: getAdminUserQueryKey(userID!),
    queryFn: processResponse(() => getUserAdminDetailUserID(userID!)),
    enabled: !!userID,
    refetchOnWindowFocus: false,
  });
