import { useMutation } from "@tanstack/react-query";
import { deletePartyPublicID } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: () => void;
};

export const useDeletePartyMutation = ({ onSuccess }: Props = {}) =>
  useMutation({
    mutationFn: processResponse((publicID: string) =>
      deletePartyPublicID(publicID),
    ),
    onSuccess: () => {
      onSuccess?.();
    },
  });
