import { Box, Typography } from "@mui/material";

type PriceProps = {
  amount: number;
};

export const Price = ({ amount }: PriceProps) => {
  const formattedAmount = new Intl.NumberFormat("cs-CZ").format(amount ?? 0);

  return (
    <Box display="flex" alignItems="baseline" gap="0.188rem">
      <Typography fontSize="0.75rem">Cena:</Typography>
      <Typography component="strong" fontSize="1.375rem" fontWeight="600">
        {formattedAmount}
      </Typography>
      <Typography fontWeight="600">Kč</Typography>
    </Box>
  );
}; 