import { PaperTitle } from "Components/Shared/PaperTitle";
import { Box } from "@mui/material";
import { ReactNode } from "react";

type FormSectionProps = {
  legend?: string;
  children: ReactNode;
};

export const FormSection = ({ legend, children }: FormSectionProps) => (
  <Box component="fieldset" sx={{ all: "unset", display: "grid", mb: 4 }}>
    {legend && (
      <PaperTitle component="legend" mb={2} p={0}>
        {legend}
      </PaperTitle>
    )}
    {children}
  </Box>
);
