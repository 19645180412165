import { useMutation } from "@tanstack/react-query";
import {
  PayTransactionResponse,
  postTransactionsPay,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";
import { toast } from "sonner";

export const useTransactionPay = () =>
  useMutation({
    mutationFn: processResponse(postTransactionsPay),
    onSuccess: (data: PayTransactionResponse) => {
      if (data.signingUrl) {
        window.location.href = data.signingUrl;
      } else {
        toast.error("Neznámá chyba během platby");
      }
    },
  });
