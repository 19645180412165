import { Typography } from "@mui/material";
import { OwnerCard } from "Components/Onboarding/BusinessEntity/Components/OwnerCard";
import { OwnerModal } from "Components/Onboarding/BusinessEntity/Components/OwnerModal/OwnerModal";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import {
  AdditionalInformationModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { BlTextButton } from "Components/Shared/Buttons/BlTextButton";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { ValuesBox } from "Components/Shared/ValuesBox/ValuesBox";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";

const StepResources =
  Resources.Onboarding.BusinessOnboarding.Steps.AdditionalInformation;

export const AdditionalInformationStep: FC = () => {
  const { t } = useResource();
  const { getFormValues, nextStep } = useOnboardingContext();

  const [modalValues, setModalValues] = useState<{ publicID?: string } | null>(
    null,
  );
  const [owners, setOwners] = useState<{ publicID: string }[]>(
    getFormValues(OnboardingSteps.AdditionalInformation).owners ?? [],
  );

  const { control, handleSubmit, watch } = useForm<AdditionalInformationModel>({
    defaultValues: getFormValues(OnboardingSteps.AdditionalInformation),
  });

  const isOwner = watch("isOwner");

  const onSubmit = (data: AdditionalInformationModel) => {
    nextStep(data);
  };

  const onOwnerSave = (publicID: string) => {
    if (owners.findIndex(o => o.publicID === publicID) === -1) {
      setOwners(p => [...p, { publicID }]);
    }

    setModalValues(null);
  };

  const onDeleteOwner = (publicID: string) => {
    setOwners(p => p.filter(o => o.publicID !== publicID));
    setModalValues(null);
  };

  const onOwnerEdit = (publicID: string) => () => {
    setModalValues({ publicID });
  };

  return (
    <>
      <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
        <StepBox
          submitButtonLabel={t(Resources.Common.Continue)}
          submitDisabled={!isOwner && !owners.length}
        >
          <CheckboxInput
            label={t(StepResources.CheckboxTitle)}
            control={control}
            name="isOwner"
            isLabelFirst
            isStrong
          />
          <Typography marginBottom={6}>
            {t(StepResources.CheckboxDescription)}
          </Typography>

          <ValuesBox title={t(StepResources.OwnersTitle)}>
            {owners.map(({ publicID }) => (
              <OwnerCard
                key={publicID}
                publicID={publicID}
                onEdit={onOwnerEdit(publicID)}
              />
            ))}
            <div>
              <BlTextButton color="primary" onClick={() => setModalValues({})}>
                +{" "}
                {t(
                  owners.length
                    ? StepResources.AddNextOwner
                    : StepResources.AddOwner,
                )}
              </BlTextButton>
            </div>
          </ValuesBox>
        </StepBox>
      </FullHeightForm>
      <OwnerModal
        onClose={() => setModalValues(null)}
        onSave={onOwnerSave}
        isOpen={!!modalValues}
        onDelete={onDeleteOwner}
        publicID={modalValues?.publicID}
      />
    </>
  );
};
