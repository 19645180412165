import { Box, Typography } from "@mui/material";
import { BankSelectorComponent } from "Components/Banking/Auth/BankSelectorComponent";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useBankingAuth } from "Hooks/useBankingAuth";
import { BankEnvironment } from "Infrastructure/Api/Api";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetTransactionListPagination } from "State/Transactions/TransactionReducer";
import { Resources, useResource } from "Translations/Resources";
import { appUrl } from "Utils/UrlUtils";

const PageResources = Resources.Banking.Auth;

export const BankingAuthPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();
  const { t } = useResource();

  const { isPending, authorize, selectedEnvironment } = useBankingAuth();

  useEffect(() => {
    return () => {
      dispatch(resetTransactionListPagination());
    };
  }, [dispatch]);

  const onBankSelected = (environment: BankEnvironment) => {
    if (!isPending) {
      authorize({ environment });
    }
  };

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      goBackUrl={appUrl("bank-address-list")}
    >
      <StyledPageWrapper>
        <div>
          <Box marginBottom={5}>
            <Typography>{t(PageResources.Description)}</Typography>
          </Box>
          <BankSelectorComponent
            onBankSelected={onBankSelected}
            isActionPending={isPending}
            selectedEnvironment={selectedEnvironment}
          />
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default BankingAuthPage;
