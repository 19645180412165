import * as React from "react";
import styled from "styled-components";
import { Skeleton } from "@mui/material";

type Props = {
  rowsCount: number;
};

const StyledSkeleton = styled(Skeleton)`
  margin-bottom: ${props => props.theme.spacing(1)};
`;

export const TableSkeleton = ({ rowsCount }: Props) => (
  <>
    {new Array(rowsCount).fill(null).map((_, i) => (
      <StyledSkeleton key={i} height={46}  />
    ))}
  </>
);
