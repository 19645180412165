import { Box, Stack, Skeleton } from "@mui/material";

type RadioButtonsSkeletonProps = {
  count?: number;
};

export const RadioButtonsSkeleton = ({
  count = 2,
}: RadioButtonsSkeletonProps) => (
  <Box ml={2} mb={2}>
    <Stack direction="row" spacing={4}>
      {Array.from({ length: count }).map((_, index) => (
        <Stack key={index} direction="row" spacing={2} alignItems="center">
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="text" width={80} height={20} />
        </Stack>
      ))}
    </Stack>
  </Box>
);
