import * as React from "react";
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton";
import styled from "styled-components";

type Props = SkeletonProps & { isLoading: boolean };

const StyledSkeleton = styled(Skeleton)`
  background-color: ${props => props.theme.palette.secondary.main}33;
  border-radius: ${props => props.theme.borderRadius};
`;

export const BlPrimarySkeletonWrapper: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ isLoading, ...rest }) => {
  if (isLoading) {
    return <StyledSkeleton {...rest} />;
  }

  return <>{rest.children}</>;
};
