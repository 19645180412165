import { Box, Typography } from "@mui/material";
import { AlertBox } from "Components/Shared/AlertBox";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";

const ComponentResource = Resources.Onboarding.PolicicallyExposed;

type Props<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  isChecked: boolean;
};

export const PoliticallyExposedCheckbox = <FormType extends FieldValues>({
  control,
  name,
  isChecked,
}: Props<FormType>) => {
  const { t } = useResource();

  return (
    <>
      <CheckboxInput
        label={t(ComponentResource.Title)}
        control={control}
        name={name}
        isLabelFirst
        isStrong
      />
      <Typography marginBottom={3}>
        {t(ComponentResource.Description)}
      </Typography>
      {!!isChecked && (
        <Box marginBottom={3}>
          <AlertBox>{t(ComponentResource.Alert)}</AlertBox>
        </Box>
      )}
    </>
  );
};
