import { useMutation } from "@tanstack/react-query";
import { getPartyQueryKey } from "Api/Queries/Party/usePartyQuery";
import { USERS_LIST_QUERY_KEY } from "Api/Queries/User/useUsersListQuery";
import { queryClient } from "App";
import { PartyDto, putPartyPublicID } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Parameters = { publicID: string; data: PartyDto };

type Props = {
  onSuccess?: (parameters: Parameters) => void;
  onError?: (error: Error) => void;
};

export const usePartyMutation = ({ onSuccess, onError }: Props = {}) =>
  useMutation({
    mutationFn: processResponse(({ publicID, data }: Parameters) =>
      putPartyPublicID(data, publicID),
    ),
    onSuccess: (_, parameters: Parameters) => {
      queryClient.invalidateQueries({
        queryKey: getPartyQueryKey(parameters.publicID),
      });
      queryClient.invalidateQueries({
        queryKey: [USERS_LIST_QUERY_KEY],
      });
      onSuccess?.(parameters);
    },
    onError: error => {
      onError?.(error);
    },
  });
