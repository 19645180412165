import { zodResolver } from "@hookform/resolvers/zod";
import { useOCR } from "Api/Mutations/Onboarding/useOCR";
import {
  OnboardingSteps,
  PersonalDocumentModel,
  useOnboardingContext,
} from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { getUserDetailFromOCRResult } from "Components/Onboarding/NaturalPerson/Utils/ocrUtils";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { logError } from "ErrorService";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";
import { ProcessingOcr } from "Components/Onboarding/Components/ProcessingOcr";
import { IdentityCardForms } from "Components/Onboarding/Components/IdentityCardForms";

const StepResources = Resources.Onboarding.Steps.Documents;

export const DocumentsStep: FC = () => {
  const { t } = useResource();
  const user = useLoggedInUser();

  const [validationSchema] = useState<z.ZodType<PersonalDocumentModel>>(
    z.object({
      frontSide: z.instanceof(File, {
        message: t(StepResources.Validations.ForegroundMissing),
      }),
      backSide: z.instanceof(File, {
        message: t(StepResources.Validations.BackgroundMissing),
      }),
    }),
  );

  const { mutateAsync: ocrRequest, isPending: isProcessingOcr } = useOCR();
  const { nextStep, setFormValues, setPartyData, getFormValues } =
    useOnboardingContext();
  const { control, handleSubmit } = useForm<PersonalDocumentModel>({
    resolver: zodResolver(validationSchema),
    defaultValues: getFormValues(OnboardingSteps.PersonalDocument),
  });

  const onSubmit = handleSubmit(async data => {
    if (isProcessingOcr) {
      return;
    }

    setFormValues(OnboardingSteps.PersonalDocument, data);

    const result = await ocrRequest({
      documents: {
        firstIDCardBackSide: data.backSide,
        firstIDCardFrontSide: data.frontSide,
      },
      // TODO: make partyPublicID required
      partyPublicID: user?.partyPublicID as string,
    });

    if (result) {
      if (!result.data?.publicID) {
        toast.error(t(StepResources.ProcessingFailed));
        logError(new Error("OCR failed - publicID missing"));

        return;
      }

      if (result.data) {
        setFormValues(
          OnboardingSteps.UserDetail,
          getUserDetailFromOCRResult(result.data),
        );

        setPartyData(result.data);
      }
      nextStep();
    }
  });

  if (isProcessingOcr) {
    return <ProcessingOcr />;
  }

  return (
    <FullHeightForm onSubmit={onSubmit}>
      <StepBox
        title={t(StepResources.LegalInformationBlock1)}
        subTitle={t(StepResources.LegalInformationBlock2)}
        submitButtonLabel={t(Resources.Common.Continue)}
        withDivider
      >
        <IdentityCardForms
          control={control}
          frontSideName="frontSide"
          backSideName="backSide"
        />
      </StepBox>
    </FullHeightForm>
  );
};
