import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { downloadUserIdentificationZipAsync } from "State/Users/Detail/GetUserIdentificationZipState";

type DownloadIdentificationsButtonProps = {
  login: string;
  userID: number;
};

export const DownloadIdentificationsButton = ({
  login,
  userID,
}: DownloadIdentificationsButtonProps) => {
  const dispatch = useDispatch();
  const { isZipDownloading } = useSelector(({ user }: RootStateType) => user); // TODO review, is the loading state needed in redux? maybe refactor with react query?

  return (
    <PrimaryButton
      fullWidth
      type="button"
      isLoading={isZipDownloading}
      onClick={() =>
        dispatch(downloadUserIdentificationZipAsync.request({ login, userID }))
      }
    >
      Stáhnout doklady
    </PrimaryButton>
  );
};
