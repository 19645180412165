import { Box, Divider, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { FC, PropsWithChildren, ReactNode } from "react";

type Props = PropsWithChildren<{
  title?: string;
  subTitle?: ReactNode;
  withDivider?: boolean;
  submitButtonLabel?: string;
  submitDisabled?: boolean;
  onSubmitClick?: () => void;
  isSubmitting?: boolean;
}>;

const TITLE_PADDING = 6;

export const StepBox: FC<Props> = ({
  title,
  subTitle,
  children,
  withDivider,
  submitButtonLabel,
  submitDisabled,
  onSubmitClick,
  isSubmitting,
}) => {
  const marginBottom = withDivider ? 0 : TITLE_PADDING;

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <div>
        {!!title && (
          <Typography marginBottom={marginBottom} marginTop={2}>
            {title}
          </Typography>
        )}
        {!!subTitle && (
          <Typography marginBottom={marginBottom}>{subTitle}</Typography>
        )}

        {withDivider && <Divider sx={{ marginY: TITLE_PADDING }} />}

        {children}
      </div>
      {!!submitButtonLabel && (
        <PrimaryButton
          color="primary"
          fullWidth
          sx={{ marginTop: 3 }}
          type={onSubmitClick ? "button" : "submit"}
          onClick={onSubmitClick}
          disabled={submitDisabled}
          isLoading={isSubmitting}
        >
          {submitButtonLabel}
        </PrimaryButton>
      )}
    </Box>
  );
};
