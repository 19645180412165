import { PartyType } from "Infrastructure/Api/Api";
import { CodeListItem } from "Models/ICodeListDto";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

type ListItem = CodeListItem<PartyType>;
type Result = ListItem[];

type Props = {
  onlyBusiness?: boolean;
};

const allEntries = Object.entries(PartyType);
const businessEntries = allEntries.filter(
  ([key]) => key !== PartyType.NaturalPerson,
);

export const usePartyTypeList = ({ onlyBusiness }: Props): Result => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      (onlyBusiness ? businessEntries : allEntries).map<ListItem>(([key]) => ({
        code: key as PartyType,
        name: t(Resources.Form.PartyType[key as PartyType]),
      })),
    [onlyBusiness, t],
  );
};
