import { DialogContent } from "@mui/material";
import { EditValues } from "Components/Onboarding/BusinessEntity/Components/OwnerModal/EditValues";
import { BlDialogTitle } from "Components/Shared/Components/BlDialogTitle";
import { StyledDialog } from "Components/Shared/Dialogs/DialogStyles";
import { FC } from "react";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  isOpen: boolean;
  onSave: (publicID: string) => void;
  onClose: () => void;
  onDelete?: (publicID: string) => void;
  publicID?: string;
};

const ModalResources = Resources.Onboarding.BusinessOnboarding.Steps.OwnerModal;

export const OwnerModal: FC<Props> = ({
  isOpen,
  onSave,
  onClose,
  onDelete,
  publicID,
}) => {
  const { t } = useResource();

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <BlDialogTitle onClose={onClose}>{t(ModalResources.Title)}</BlDialogTitle>

      <DialogContent>
        <EditValues publicID={publicID} onSave={onSave} onDelete={onDelete} />
      </DialogContent>
    </StyledDialog>
  );
};
