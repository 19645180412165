import { Typography } from "@mui/material";
import { useResource } from "Translations/Resources";
import { Resources } from "Translations/Resources";

const PageResources = Resources.BankAccounts.List;

export const NoAccountsMessage = () => {
  const { t } = useResource();

  return (
    <Typography marginTop={6} marginBottom={6} fontSize={16} fontWeight={600}>
      {t(PageResources.NoAccounts)}
    </Typography>
  );
};
