import type { FC } from "react";

import airBank from "Assets/Images/Banks/ab.png";
import kb from "Assets/Images/Banks/kb.png";
//import rb from "Assets/Images/Banks/rb.png";
import csas from "Assets/Images/Banks/csas.png";
import csob from "Assets/Images/Banks/csob.png";
//import fio from "Assets/Images/Banks/fio.png";
import unicreditBank from "Assets/Images/Banks/unicredit_bank.jpg";
import type { Bank } from "Infrastructure/Api/Api";
import { Box, Skeleton } from "@mui/material";

const HEIGHT = 32;
const HEIGHT_SMALL = 24;
const WIDTH = 64;
const WIDTH_SMALL = 48;

type Logo = {
  image: string;
  dimensions: {
    normal: { width?: number; height?: number };
    small: { width?: number; height?: number };
  };
};

const LOGOS: Record<Bank | "Unknown", Logo> = {
  CSOB: {
    image: csob,
    dimensions: {
      normal: { height: HEIGHT },
      small: { height: HEIGHT_SMALL },
    },
  },
  CSAS: {
    image: csas,
    dimensions: {
      normal: { height: HEIGHT },
      small: { height: HEIGHT_SMALL },
    },
  },
  KB: {
    image: kb,
    dimensions: {
      normal: { height: HEIGHT },
      small: { height: HEIGHT_SMALL },
    },
  },
  AirBank: {
    image: airBank,
    dimensions: {
      normal: { height: HEIGHT },
      small: { height: HEIGHT_SMALL },
    },
  },
  UCB: {
    image: unicreditBank,
    dimensions: {
      normal: { width: WIDTH },
      small: { width: WIDTH_SMALL },
    },
  },
  Unknown: {
    image: "",
    dimensions: {
      normal: { height: HEIGHT, width: WIDTH },
      small: { height: HEIGHT_SMALL, width: WIDTH_SMALL },
    },
  },
  //FIO: { image: fio, height: HEIGHT },
};

const getImageUrl = (bank: Bank) =>
  new URL(LOGOS[bank].image, import.meta.url).href;

type Props = {
  bank?: Bank | "Unknown";
  name?: string;
  isLoading?: boolean;
  isRounded?: boolean;
  size?: "normal" | "small";
  isGrayscale?: boolean;
};

export const BankImage = ({
  bank,
  name,
  isLoading,
  isRounded,
  size = "normal",
  isGrayscale
}: Props) => {

  if (isLoading) {
    return (
      <Skeleton
        variant={isRounded ? "rounded" : "rectangular"}
        width={120}
        height={size === "small" ? HEIGHT_SMALL : HEIGHT}
      />
    );
  }

  if (!bank) {
    return null;
  }

  const logo = LOGOS[bank];
  const dimensions = logo.dimensions[size];

  if (bank === "Unknown") {
    return <Box height={dimensions.height} width={dimensions.width} />;
  }

  return (
    <Box
      component="img"
      sx={{
        filter: isGrayscale ? "grayscale(100%)" : "none",
      }}
      src={getImageUrl(bank)}
      height={dimensions.height}
      width={dimensions.width}
      alt={`${name || "bank"} logo`}
      style={{ borderRadius: isRounded ? "24px" : "0" }}
    />
  );
};
