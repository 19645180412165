import {
  FormModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { UserVerificationDto } from "Infrastructure/Api/Api";
import { DeepPartial } from "types";

export const getFormDataFromVerifications = (
  verifications: UserVerificationDto,
  form: DeepPartial<FormModel>,
): DeepPartial<FormModel> => {
  return {
    ...form,
    [OnboardingSteps.IncomeInformation]: {
      averageTransactionValue:
        verifications.averageTransactionValue ?? undefined,
      incomeSource: verifications.sourceOfIncome ?? undefined,
      incomeSourceDetail: verifications.otherSourceOfIncomeDetails ?? undefined,
      isPoliticallyExposed: verifications.isPoliticallyExposedPerson,
      businessActivity: verifications.businessActivity ?? undefined,
      expectedMonthlyRevenue: verifications.expectedMonthlyRevenue ?? undefined,
      otherBusinessActivityDetails:
        verifications.otherBusinessActivityDetails ?? undefined,
      taxResidence: verifications.taxResidencyCountryID ?? undefined,
      ...form[OnboardingSteps.IncomeInformation],
    },
  };
};
