import { useQuery } from "@tanstack/react-query";
import { getVerifications } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const VERIFICATIONS_QUERY_KEY = ["VERIFICATIONS"];

export const getVerificationsQueryKey = (userID: number) => [
  VERIFICATIONS_QUERY_KEY,
  { userID },
];

export const useVerificationsQuery = (userID?: number) =>
  useQuery({
    queryKey: userID
      ? getVerificationsQueryKey(userID)
      : [VERIFICATIONS_QUERY_KEY],
    queryFn: processResponse(() => getVerifications(userID)),
    refetchOnWindowFocus: false,
  });
