import { useMutation } from "@tanstack/react-query";
import {
  InitiateTransactionResponse,
  postTransactionsInitiate,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: (publicID: InitiateTransactionResponse) => void;
};

export const useTransactionInitiateMutation = ({ onSuccess }: Props = {}) => {
  return useMutation({
    mutationFn: processResponse(postTransactionsInitiate),
    onSuccess: (result: InitiateTransactionResponse) => {
      onSuccess?.(result);
    },
  });
};
