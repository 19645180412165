import { GetUserListQuery } from "Api/Api";
import { useUsersListQuery } from "Api/Queries/User/useUsersListQuery";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { UserListTable } from "Components/Users/UserListTable";
import { useCallback, useState } from "react";

const ITEMS_PER_PAGE = 25;

export const UserListPage = () => {
  const [paginationParams, setPaginationParams] = useState<GetUserListQuery>({
    limit: ITEMS_PER_PAGE,
    offset: 0,
  });

  const { data: userDetailData, isLoading: isUserDetailDataLoading } =
    useUsersListQuery(paginationParams);

  const handlePageChange = useCallback((newPage: number, newRows: number) => {
    setPaginationParams({
      limit: newRows,
      offset: newPage * newRows,
    });
  }, []);

  return (
    <AuthenticatedLayout title="Uživatelé">
      <StyledPageWrapper style={{ maxWidth: "750px" }}>
        <div className="left-content" style={{ overflowX: "scroll" }}>
          <UserListTable
            isLoading={isUserDetailDataLoading}
            list={userDetailData || null}
            rowsPerPage={ITEMS_PER_PAGE}
            onPageChange={handlePageChange}
          />
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default UserListPage;
