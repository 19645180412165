import { Box, Typography } from "@mui/material";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { BlDivider } from "Components/Shared/BlDivider";
import { Fragment } from "react";
import { ConnectedAccountsSkeleton } from "Components/Dashboard/ConnectedAccounts/Components/ConnectedAccountsSkeleton";
import { NoAccountsMessage } from "Components/Dashboard/ConnectedAccounts/Components/NoAccountsMessage";
import { Account } from "Components/Dashboard/ConnectedAccounts/Components/Account";

export const ConnectedAccounts = () => {
  const { data: accounts, isLoading, isError } = useBankingAccounts();

  if (isLoading) {
    return <ConnectedAccountsSkeleton />;
  }

  if (!accounts || isError) {
    return (
      <PrimaryPaper sx={{ py: 4 }}>
        <Box mb={4}>
          <PaperTitle>Připojené účty</PaperTitle>
        </Box>
        <Typography color="error.main">
          Připojené účty se nepodařilo načíst.
        </Typography>
      </PrimaryPaper>
    );
  }

  return (
    <PrimaryPaper sx={{ py: 4 }}>
      <Box mb={4}>
        <PaperTitle>Připojené účty</PaperTitle>
      </Box>
      {accounts.items.length > 0 ? (
        <Box display="flex" flexDirection="column" gap={2}>
          {accounts.items.map(({ bankAccountID, ...account }, index) => (
            <Fragment key={bankAccountID}>
              {index !== 0 && <BlDivider marginY={1} />}
              <Account {...account} />
            </Fragment>
          ))}
        </Box>
      ) : (
        <NoAccountsMessage />
      )}
    </PrimaryPaper>
  );
};
