import {
  FormModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { getUserDetailFromParty } from "Components/Onboarding/BusinessEntity/Utils/partyUtils";
import { PartyDto } from "Infrastructure/Api/Api";
import { DeepPartial } from "types";

export const getFormDataFromOCR = (
  party: PartyDto,
  form: DeepPartial<FormModel>,
): DeepPartial<FormModel> => {
  return {
    ...form,
    [OnboardingSteps.UserDetail]: {
      ...form[OnboardingSteps.UserDetail],
      ...getUserDetailFromParty(party),
    },
  };
};
