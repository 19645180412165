import { Box, Typography } from "@mui/material";

type BalanceDisplayProps = {
  totalAvailableFunds: string;
};

export const BalanceDisplay = ({
  totalAvailableFunds,
}: BalanceDisplayProps) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Typography
      variant="subtitle1"
      component="div"
      color="primary.contrastText"
    >
      Dostupné prostředky
    </Typography>
    <Box display="flex" alignItems="baseline" gap={1}>
      <Typography
        variant="h2"
        component="div"
        color="primary.contrastText"
        fontWeight="bold"
        display="inline-flex"
        alignItems="baseline"
        gap={1}
      >
        {totalAvailableFunds}
      </Typography>
      <Typography variant="h6" fontSize="1.1rem" color="primary.contrastText">
        Kč
      </Typography>
    </Box>
  </Box>
);
