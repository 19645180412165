import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { usePartyTypeList } from "Hooks/Options/usePartyTypeList";
import { Control, FieldPath, FieldValues } from "react-hook-form";

type Props<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  label?: string;
};

export const BusinessDropdown = <FormType extends FieldValues>({
  control,
  name,
  label,
}: Props<FormType>) => {
  const codeListBusiness = usePartyTypeList({ onlyBusiness: true });

  return (
    <Dropdown
      codeList={codeListBusiness}
      name={name}
      control={control}
      label={label}
    />
  );
};
