import { zodResolver } from "@hookform/resolvers/zod";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { useUserDetailValidationSchema } from "Components/Onboarding/NaturalPerson/Hooks/useUserDetailValidationSchema";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";
import { EditValues } from "Components/Onboarding/Components/PersonalInformation/EditValues";
import { ViewValues } from "Components/Onboarding/Components/PersonalInformation/ViewValues";
import { UserDetailModel } from "Components/Onboarding/types";

const StepResources = Resources.Onboarding.Steps.PersonalInformation;

type FormModel = {
  isUserConfirmed: boolean;
};

type Props = {
  initialValues: Partial<UserDetailModel>;
  onSave: (values: UserDetailModel) => void;
};

export const PersonalInformation: FC<Props> = ({ initialValues, onSave }) => {
  const { t } = useResource();
  const [values, setValues] = useState<Partial<UserDetailModel>>(initialValues);
  const [validationSchema] = useState<z.ZodType<FormModel>>(
    z.object({
      isUserConfirmed: z.boolean().refine(value => value, {
        message: t(StepResources.ConfirmDataRequest),
      }),
    }),
  );

  const { userDetailValidationSchema } = useUserDetailValidationSchema();
  const [isEdit, setIsEdit] = useState(false);

  const { control, handleSubmit, watch } = useForm<FormModel>({
    resolver: zodResolver(validationSchema),
  });
  const isUserConfirmed = watch("isUserConfirmed");

  const onEdit = () => {
    setIsEdit(true);
  };

  const handleSave = (values: UserDetailModel) => {
    setValues(values);
    setIsEdit(false);
  };

  const onSubmit = () => {
    if (userDetailValidationSchema.safeParse(values).success) {
      onSave(values as UserDetailModel);

      return;
    }

    toast.error(t(Resources.Common.FillMissing));
  };

  return (
    <>
      {isEdit ? (
        <EditValues onSave={handleSave} initialValues={values} />
      ) : (
        <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
          <StepBox
            title={t(StepResources.Title)}
            submitButtonLabel={t(Resources.Common.Continue)}
            submitDisabled={!isUserConfirmed}
            withDivider
          >
            <ViewValues onEdit={onEdit} userDetail={values} />
            <CheckboxInput
              label={t(StepResources.DataConfirmation)}
              control={control}
              name="isUserConfirmed"
            />
          </StepBox>
        </FullHeightForm>
      )}
    </>
  );
};
