import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { useRelationTypeValueList } from "Hooks/Options/useRelationTypeValueList";
import { Control, FieldPath, FieldValues } from "react-hook-form";

type Props<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  label?: string;
  excludeBeneficialOwner?: boolean;
  disabled?: boolean;
};

export const RelationTypeDropdown = <FormType extends FieldValues>({
  control,
  name,
  label,
  excludeBeneficialOwner,
  disabled,
}: Props<FormType>) => {
  const codeList = useRelationTypeValueList({ excludeBeneficialOwner });

  return (
    <Dropdown
      codeList={codeList}
      name={name}
      control={control}
      label={label}
      disabled={disabled}
    />
  );
};
