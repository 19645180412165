import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { CzechResourcesDictionary } from "Translations/CzechResourcesDictionary";
import { Routes } from "Routes/Routes";
import { Layout } from "Components/Layout/Layout";
import { Provider } from "react-redux";
import appStore, { RootStateType } from "State/Store";
import { PersistGate } from "redux-persist/integration/react";
import { configureApiCalls } from "Api/Api";
import { resetUser, extendSignOutDate } from "State/Auth/AuthReducer";
import { BrowserRouter } from "react-router-dom";
import { ModuleLoadingFallback } from "Components/Layout/ModuleLoadingFallback";
import { JWT_KEY } from "Utils/AuthUtils";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { trackUserIdentification } from "Utils/TrackingUtils";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ApiCallError, handleResponseError } from "Infrastructure/Api/ApiUtils";
import { FetchResponse, setupClient } from "Infrastructure/Api/Api";
import { processSignOut } from "Auth/Hooks/useSignOut";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import { Suspense, useEffect } from "react";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      cs: CzechResourcesDictionary,
    },
    lng: "cs",
    fallbackLng: "cs",

    interpolation: {
      escapeValue: false,
    },
  });

export const QUERY_MAX_RETRY_COUNT = 2;

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: handleResponseError,
    },
    queries: {
      retry: (failureCount, error) => {
        if (error instanceof ApiCallError && error.data.status >= 500) {
          return failureCount < QUERY_MAX_RETRY_COUNT;
        }

        return false;
      },
    },
  },
  queryCache: new QueryCache({
    onError: handleResponseError,
  }),
});

const responseMiddleware = {
  name: "auth-middleware",
  fn: (response: FetchResponse<unknown, any>) => {
    if (response.status === 401) {
      processSignOut(queryClient);
    } else {
      trackUserIdentification();
    }
    return response;
  },
};

setupClient({
  apiUrl: import.meta.env.VITE_API_URL,
  jwtKey: JWT_KEY,
  responseMiddlewares: [responseMiddleware],
});

function App() {
  configureApiCalls({
    jwtKey: JWT_KEY,
    onResponse: r => {
      if (
        r.status !== 200 &&
        r.status !== 201 &&
        r.status !== 401 &&
        r.status !== 503
      ) {
        Sentry.captureEvent({
          message: `Problem while calling API ${JSON.stringify(r)}`,
          level: "log",
        });
      }
      if (r.status === 401) {
        appStore.store.dispatch(resetUser({}));
      } else {
        appStore.store.dispatch(extendSignOutDate());
        trackUserIdentification();
      }
    },
  });

  z.setErrorMap(zodI18nMap);

  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

  useEffect(() => {
    const store = appStore.store.getState() as Partial<RootStateType>;

    // one time fetch after app start
    if (store?.auth?.user && !store?.auth?.isUserFetching) {
      appStore.store.dispatch(
        getUserInfoAsync.request({ withLoadingStatus: true }),
      );
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={appStore.store}>
        <PersistGate loading={null} persistor={appStore.persistor}>
          <GoogleReCaptchaProvider
            reCaptchaKey="6Lda2ZciAAAAAGjaHbO8SFHLUWxI31AGLbNl3M11"
            useEnterprise={true}
            scriptProps={{
              async: true,
              defer: true,
              appendTo: "body",
              nonce: undefined,
            }}
          >
            <GoogleOAuthProvider clientId={clientId}>
              <BrowserRouter>
                <Layout>
                  <Suspense fallback={<ModuleLoadingFallback />}>
                    <Routes />
                  </Suspense>
                </Layout>
              </BrowserRouter>
            </GoogleOAuthProvider>
          </GoogleReCaptchaProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
