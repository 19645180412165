import { BusinessInformationStep } from "Components/Onboarding/BusinessEntity/Steps/BusinessInformationStep";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { OnboardingSteps } from "Components/Onboarding/BusinessEntity/types";
import { FC } from "react";
import { IncomeInformationStep } from "Components/Onboarding/BusinessEntity/Steps/IncomeInformationStep";
import { PersonalDocumentsStep } from "Components/Onboarding/BusinessEntity/Steps/PersonalDocumentsStep";
import { PersonalInformationStep } from "Components/Onboarding/BusinessEntity/Steps/PersonalInformationStep";
import { AdditionalInformationStep } from "Components/Onboarding/BusinessEntity/Steps/AdditionalInformationStep";
import { BankAccountStep } from "Components/Onboarding/BusinessEntity/Steps/BankAccountStep";
import { ManualAccountVerificationStep } from "Components/Onboarding/BusinessEntity/Steps/ManualAccountVerificationStep";
import { OnboardingLayout } from "Components/Layout/OnboardingLayout";
import { Resources, useResource } from "Translations/Resources";
import { LicenseSelectionStep } from "Components/Onboarding/BusinessEntity/Steps/LicenseSelectionStep";
import { LicensePaymentStep } from "Components/Onboarding/BusinessEntity/Steps/LicensePaymentStep";

const StepsSelector: FC = () => {
  const { step } = useOnboardingContext();

  if (step === OnboardingSteps.BusinessInformation) {
    return <BusinessInformationStep />;
  } else if (step === OnboardingSteps.IncomeInformation) {
    return <IncomeInformationStep />;
  } else if (step === OnboardingSteps.PersonalDocument) {
    return <PersonalDocumentsStep />;
  } else if (step === OnboardingSteps.UserDetail) {
    return <PersonalInformationStep />;
  } else if (step === OnboardingSteps.AdditionalInformation) {
    return <AdditionalInformationStep />;
  } else if (step === OnboardingSteps.BankAccountConnection) {
    return <BankAccountStep />;
  } else if (step === OnboardingSteps.ManualAccountVerification) {
    return <ManualAccountVerificationStep />;
  } else if (step === OnboardingSteps.LicenseSelection) {
    return <LicenseSelectionStep />;
  } else if (step === OnboardingSteps.LicensePayment) {
    return <LicensePaymentStep />;
  }

  return null;
};

export const BusinessEntityOnboarding: FC = () => {
  const { t } = useResource();
  const { hasPrevStep, prevStep, isLoadingOnboarding, step } =
    useOnboardingContext();

  const getTitle = (step: OnboardingSteps): string => {
    if (step === OnboardingSteps.BankAccountConnection) {
      return t(Resources.Onboarding.BankAccountConnectionTitle);
    } else if (step === OnboardingSteps.ManualAccountVerification) {
      return t(Resources.Onboarding.BankAccountVerificationTitle);
    } else if (step === OnboardingSteps.AdditionalInformation) {
      return t(Resources.Onboarding.AdditionalInformationTitle);
    } else if (step === OnboardingSteps.LicenseSelection) {
      return t(Resources.Onboarding.LicenseSelectionTitle);
    } else if (step === OnboardingSteps.LicensePayment) {
      return t(Resources.Onboarding.LicensePaymentTitle);
    }

    return t(Resources.Onboarding.ProcessTitle);
  };

  return (
    <OnboardingLayout
      title={getTitle(step)}
      onChevronClick={hasPrevStep ? prevStep : undefined}
    >
      {/* //TODO: Loading indicator*/}
      {isLoadingOnboarding ? <div>Loading</div> : <StepsSelector />}
    </OnboardingLayout>
  );
};
