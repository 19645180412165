import { useTransactionsSum } from "Api/Queries/Transactions/useTransactionsSum";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { PaymentActions } from "Components/Merchant/Payment/PaymentActions";
import { AccountBalance } from "Components/Shared/AccountBalance";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { endOfDay, startOfDay } from "date-fns";
import { TransactionStatus2 } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { isNoU } from "Utils/ObjectUtils";

const PageResources = Resources.Merchant.Payment;

export const MerchantPaymentComponent = () => {
  const { t } = useResource();

  const { data: accounts } = useBankingAccounts();
  const { data: sum, isLoading: isLoadingSum } = useTransactionsSum({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    allowedStatuses: [
      TransactionStatus2.Success,
      TransactionStatus2.Processing,
    ],
  });

  const sortedAccounts = accounts?.items?.sort((a, b) => b.rank - a.rank);
  const firstAccount = sortedAccounts?.[0];
  const totalSum = sum?.totalSum;

  return (
    <PrimaryPaper>
      <PaperTitle>{t(PageResources.Title)}</PaperTitle>
      <AccountBalance
        balance={totalSum}
        bank={firstAccount?.bank}
        isLoading={isLoadingSum && isNoU(totalSum)}
      />
      <PaymentActions publicID={firstAccount?.publicID} />
    </PrimaryPaper>
  );
};
