import { Typography, Tooltip } from "@mui/material";
import { UserDto } from "../../Infrastructure/Api/Api";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { isUserVerified } from "Components/Users/utils";

type VerificationCellProps = {
  user: UserDto;
};

export const VerificationCell = ({ user }: VerificationCellProps) => (
  <Tooltip
    title={
      <>
        <Typography>
          Dodatečné informace: {user.verificationAdditionalInfoStatus}
        </Typography>
        <Typography>
          ID: {user.verificationIdentityVerificationStatus}
        </Typography>
        <Typography>Risk: {user.verificationRiskProfile}</Typography>
        <Typography>
          Bank. účet: {user.verificationBankVerificationStatus}
        </Typography>
      </>
    }
  >
    {isUserVerified(user) ? (
      <CheckCircleOutlineIcon color="secondary" />
    ) : (
      <InfoIcon color="warning" />
    )}
  </Tooltip>
);
