import { Box, Typography } from "@mui/material";
import { LicenseTier } from "Components/Onboarding/BusinessEntity/types";

type DescriptionProps = {
  licenseTier: LicenseTier;
};

type LicenseNote = {
  prefix: string;
  emphasis: string;
  suffix: string;
};

const LICENSE_DESCRIPTIONS: Record<LicenseTier, string> = {
  gold: "Tato licence přináší přístup k HCRM systému a velký obraz, spojující umění a funkčnost.",
  silver:
    "Získejte přístup k pokročilým funkcím HCRM systému a středně velký obraz. Ideální pro rostoucí podnikatele.",
  bronze:
    "Základní přístup k HCRM systému s menším obrazem. Perfektní start pro začínající podnikatele.",
  basic:
    "Jednoduchý přístup k základním funkcím HCRM systému. Vhodné pro ty, kteří chtějí systém nejdříve vyzkoušet.",
};

const LICENSE_NOTES: Record<LicenseTier, LicenseNote> = {
  gold: {
    prefix: "Jedná se o ",
    emphasis: "nejhodnotnější",
    suffix: " verzi licence.",
  },
  silver: {
    prefix: "Jedná se o ",
    emphasis: "pokročilou",
    suffix: " verzi licence.",
  },
  bronze: {
    prefix: "Jedná se o ",
    emphasis: "základní",
    suffix: " verzi licence.",
  },
  basic: {
    prefix: "Jedná se o ",
    emphasis: "startovací",
    suffix: " verzi licence.",
  },
};

export const Description = ({ licenseTier }: DescriptionProps) => {
  const description = LICENSE_DESCRIPTIONS[licenseTier];
  const note = LICENSE_NOTES[licenseTier];

  return (
    <Box>
      <Typography mb={1}>{description}</Typography>
      {note.prefix}
      <strong>{note.emphasis}</strong>
      {note.suffix}
    </Box>
  );
};
