import { zodResolver } from "@hookform/resolvers/zod";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";
import {
  IncomeInformationModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { IncomeSourceDropdown } from "Components/Shared/SelectorsForm/IncomeSourceDropdown";
import { CountryDropdown } from "Components/Shared/SelectorsForm/CountryDropdown";
import { PoliticallyExposedCheckbox } from "Components/Onboarding/Components/PoliticallyExposedCheckbox";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import {
  AverageTransactionValue,
  BusinessActivity,
  ExpectedMonthlyRevenue,
  PartyType,
  RelationType,
  SourceOfIncome,
} from "Infrastructure/Api/Api";
import { ExpectedMonthlyRevenueDropdown } from "Components/Shared/SelectorsForm/ExpectedMonthlyRevenueDropdown.";
import { AverageTransactionValueDropdown } from "Components/Shared/SelectorsForm/AverageTransactionValueDropdown";
import { BusinessActivityDropdown } from "Components/Shared/SelectorsForm/BusinessActivityDropdown";
import { RelationTypeDropdown } from "Components/Shared/SelectorsForm/RelationTypeDropdown";

export const IncomeInformationStep: FC = () => {
  const { t } = useResource();
  const { nextStep, getFormValues, isLoadingNextStep } = useOnboardingContext();

  const [validationSchema] = useState<z.ZodType<IncomeInformationModel>>(() =>
    z
      .object({
        businessActivity: z.nativeEnum(BusinessActivity),
        // otherBusinessActivityDetails is required when businessActivity is Other and optional otherwise
        otherBusinessActivityDetails: z.string().optional(),
        incomeSource: z.nativeEnum(SourceOfIncome),
        incomeSourceDetail: z.string().optional(),
        expectedMonthlyRevenue: z.nativeEnum(ExpectedMonthlyRevenue),
        averageTransactionValue: z.nativeEnum(AverageTransactionValue),
        taxResidence: z.number(),
        relationType:
          getFormValues(OnboardingSteps.BusinessInformation)?.partyType ===
          PartyType.LegalEntity
            ? z.nativeEnum(RelationType)
            : z.nativeEnum(RelationType).optional(),
        isPoliticallyExposed: z.boolean(),
      })
      .superRefine((data, ctx) => {
        if (
          data.businessActivity === BusinessActivity.Other &&
          !data.otherBusinessActivityDetails
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["otherBusinessActivityDetails"],
            message: t(Resources.Common.Required),
          });
        }
      }),
  );

  const { control, handleSubmit, watch } = useForm<IncomeInformationModel>({
    defaultValues: {
      isPoliticallyExposed: false,
      ...getFormValues(OnboardingSteps.IncomeInformation),
    },
    resolver: zodResolver(validationSchema),
  });

  const politicallyExposed = watch("isPoliticallyExposed");
  const businessActivity = watch("businessActivity");
  const incomeSource = watch("incomeSource");
  const { partyType } = getFormValues(OnboardingSteps.BusinessInformation);

  const onSubmit = (data: IncomeInformationModel) => {
    if (politicallyExposed) {
      return;
    }

    nextStep(data);
  };

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        submitButtonLabel="Pokračovat"
        submitDisabled={politicallyExposed}
        isSubmiting={isLoadingNextStep}
      >
        <BusinessActivityDropdown
          control={control}
          name="businessActivity"
          label="Předmět podnikání"
        />
        {businessActivity === BusinessActivity.Other && (
          <FormInput
            control={control}
            name="otherBusinessActivityDetails"
            label="Upřesněte předmět podnikání"
          />
        )}
        <IncomeSourceDropdown
          control={control}
          name="incomeSource"
          label="Zdroj příjmů"
        />
        {incomeSource === SourceOfIncome.Other && (
          <FormInput
            control={control}
            name="incomeSourceDetail"
            label="Upřesněte zdroj příjmů"
          />
        )}
        <ExpectedMonthlyRevenueDropdown
          control={control}
          name="expectedMonthlyRevenue"
          label="Očekávaný měsíční objem"
        />
        <AverageTransactionValueDropdown
          control={control}
          name="averageTransactionValue"
          label="Průměrná hodnota jedné transakce"
        />
        <CountryDropdown
          control={control}
          name="taxResidence"
          label="Daňová rezidence"
        />
        {partyType === PartyType.LegalEntity && (
          <RelationTypeDropdown
            control={control}
            name="relationType"
            label="Vaše pozice"
            excludeBeneficialOwner
          />
        )}
        <PoliticallyExposedCheckbox
          control={control}
          name="isPoliticallyExposed"
          isChecked={politicallyExposed}
        />
      </StepBox>
    </FullHeightForm>
  );
};
