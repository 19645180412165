import { extractIBAN } from "ibantools";

// CZ and SK have the same format of IBAN (XXkk bbbb pppp ppcc cccc cccc)
export const getLocalBankAccountNumberFromIBAN = (IBAN: string): string => {
  const { valid, iban, countryCode } = extractIBAN(IBAN);

  if (!valid || (countryCode !== "CZ" && countryCode !== "SK")) {
    return IBAN;
  }

  const bank = iban.slice(4, 8);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _prefix = iban.slice(8, 14); // not needed now
  const account = iban.slice(14, 24);

  return `${account}/${bank}`;
};
