import { ValueItems } from "Components/Onboarding/NaturalPerson/Components/ValueItems";
import { ValuesBox } from "Components/Onboarding/NaturalPerson/Components/ValuesBox";
import { usePersonalInformationValues } from "Components/Onboarding/hooks/usePersonalInformationValues";
import { UserDetailModel } from "Components/Onboarding/types";
import { FC } from "react";
import { Resources, useResource } from "Translations/Resources";

const StepResources = Resources.Onboarding.Steps.PersonalInformation;

type Props = {
  onEdit: () => void;
  userDetail?: Partial<UserDetailModel>;
};

export const ViewValues: FC<Props> = ({ onEdit, userDetail }) => {
  const { t } = useResource();
  const [personalInfo, address, identityDocument] =
    usePersonalInformationValues(userDetail);

  return (
    <>
      <ValuesBox
        title={t(StepResources.FormGroups.PersonalInformation)}
        onEdit={onEdit}
      >
        <ValueItems values={personalInfo} />
      </ValuesBox>
      <ValuesBox title={t(StepResources.FormGroups.Address)} onEdit={onEdit}>
        <ValueItems values={address} />
      </ValuesBox>
      <ValuesBox
        title={t(StepResources.FormGroups.IdentityDocument)}
        onEdit={onEdit}
      >
        <ValueItems values={identityDocument} />
      </ValuesBox>
    </>
  );
};
