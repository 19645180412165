import { zodResolver } from "@hookform/resolvers/zod";
import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { useUpdateVerificationsMutation } from "Api/Mutations/Verifications/useUpdateVerificationsMutation";
import {
  OnboardingSteps,
  useOnboardingContext,
} from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { ValuesBox } from "Components/Onboarding/NaturalPerson/Components/ValuesBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";
import { PoliticallyExposedCheckbox } from "Components/Onboarding/Components/PoliticallyExposedCheckbox";
import { useVerificationsMutation } from "Api/Mutations/Verifications/useVerificationsMutation";

const validationSchema = z.object({
  phone: z.string().min(12, "Prosím, zadejte platné telefonní číslo"),
  politicallyExposed: z.boolean(),
});

const StepResource = Resources.Onboarding.Steps.FinalStep;

type FormModel = z.infer<typeof validationSchema>;

export const FinalStep: FC = () => {
  const { t } = useResource();
  const dispatch = useDispatch();
  const { mutate, isPending } = usePartyMutation({
    onSuccess: () => {
      verify({
        isPoliticallyExposedPerson: getValues().politicallyExposed ?? false,
      });
    },
  });
  const { mutate: postVerifications, isPending: isPostingVerifications } =
    useVerificationsMutation({
      onSuccess: () => {
        // after we refresh the user info with the new data, the user will be redirected to the dashboard
        dispatch(getUserInfoAsync.request(undefined));
      },
    });
  const { mutate: verify, isPending: isVerifying } =
    useUpdateVerificationsMutation({
      onSuccess: () => {
        postVerifications();
      },
    });

  const { getPartyValues, setFormValues } = useOnboardingContext();
  const { control, handleSubmit, watch, getValues } = useForm<FormModel>({
    defaultValues: {
      phone: "420",
      politicallyExposed: false,
    },
    resolver: zodResolver(validationSchema),
  });

  const politicallyExposed = watch("politicallyExposed");

  const onSubmit = (data: FormModel) => {
    if (politicallyExposed) {
      return;
    }

    setFormValues(OnboardingSteps.LastStep, data);
    const { party, publicID } = getPartyValues();

    mutate({ data: party, publicID });
  };

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        submitButtonLabel={t(StepResource.SubmitButton)}
        submitDisabled={politicallyExposed}
        isSubmiting={isPending || isVerifying || isPostingVerifications}
      >
        <ValuesBox title={t(Resources.Common.Contact)} hideDivider>
          <PoliticallyExposedCheckbox
            control={control}
            name="politicallyExposed"
            isChecked={politicallyExposed}
          />
          <FormInput
            control={control}
            name="phone"
            label={t(Resources.Common.Phone)}
            mask="+000 000 000 000"
            inputProps={{
              inputMode: "tel",
            }}
          />
        </ValuesBox>
      </StepBox>
    </FullHeightForm>
  );
};
