import { Balance } from "Components/Shared/Balance";

import { Box, Typography } from "@mui/material";
import { BalanceContainer } from "Components/Dashboard/BalanceWithPaymentActions/BalanceContainer";
import { MobileBalanceSkeleton } from "Components/Dashboard/BalanceWithPaymentActions/MobileBalance/Components/MobileBalanceSkeleton";
import { Resources, useResource } from "Translations/Resources";
import { PaymentActions } from "Components/Dashboard/BalanceWithPaymentActions/MobileBalance/Components/PaymentActions";

const BalanceResources = Resources.Balance.BalanceContent;

export const MobileBalance = () => {
  const { t } = useResource();

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          variant="subtitle1"
          component="div"
          color="primary.contrastText"
        >
          {t(BalanceResources.Title)}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={12}
        pb={4}
      >
        <BalanceContainer
          renderBalance={props => <Balance {...props} colorVariant="light" />}
          renderActions={PaymentActions}
          renderLoading={MobileBalanceSkeleton}
          renderError={() => <MobileBalanceSkeleton isError />}
        />
      </Box>
    </Box>
  );
};
