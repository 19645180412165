import {
  FormModel,
  OnboardingSteps,
} from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import {
  AddressDto,
  AddressType,
  PartyDto,
  PartyType,
} from "Infrastructure/Api/Api";

export const getPartyDataFromForm = (
  form: FormModel,
  party: PartyDto,
): PartyDto => {
  const userDetails = form[OnboardingSteps.UserDetail];
  const lastStepDetails = form[OnboardingSteps.LastStep];

  const pernamentAddress = party.addresses.find(
    ({ type }) => type === AddressType.Permanent,
  );

  const addresses: AddressDto[] = [
    {
      addressID: userDetails.address?.addressID ?? pernamentAddress?.addressID,
      type: AddressType.Permanent,
      street: userDetails.address.street,
      streetNumber: userDetails.address.descriptionNumber,
      orientationNumber: userDetails.address.orientationNumber,
      postalCode: userDetails.address.postalCode,
      municipality: userDetails.address.city,
    },
  ];

  const identificationValidFrom = userDetails.document.valid.from.toISOString();
  const identificationValidTo = userDetails.document.valid.to.toISOString();

  const partyDto: PartyDto = {
    ...party,
    type: PartyType.NaturalPerson,
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    personalNumber: userDetails.personalIdentificationNumber,
    birthPlace: userDetails.birthPlace,
    nationalityCountryID: userDetails.country,
    gender: userDetails.gender,
    isForeigner: false,
    phone: lastStepDetails.phone,
    identification: userDetails.document.type,
    identificationNumber: userDetails.document.number,
    identificationValidFrom,
    identificationValidTo,
    identificationIssuer: userDetails.document.issued,
    addresses,
  };

  return partyDto;
};
