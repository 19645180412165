import { getPartyDataFromForm } from "Components/Onboarding/NaturalPerson/Utils/partyUtils";
import { UserDetailModel } from "Components/Onboarding/types";
import { PartyDto } from "Infrastructure/Api/Api";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useRef,
  useState,
} from "react";

export enum OnboardingSteps {
  PersonalDocument = 0,
  UserDetail = 1,
  LastStep = 2,
}

export type PersonalDocumentModel = {
  frontSide: File;
  backSide: File;
};

export type LastStepModel = {
  phone: string;
  politicallyExposed: boolean;
};

export type FormModel = {
  [OnboardingSteps.PersonalDocument]: PersonalDocumentModel;
  [OnboardingSteps.UserDetail]: UserDetailModel;
  [OnboardingSteps.LastStep]: LastStepModel;
};

interface GetFormValuesInterface {
  <T extends OnboardingSteps>(step: T): Partial<FormModel[T]> | undefined;
}

type OnboardingContextType = {
  step: OnboardingSteps;
  nextStep: () => void;
  prevStep: () => void;
  setFormValues: (step: OnboardingSteps, values: unknown) => void;
  getFormValues: GetFormValuesInterface;
  getPartyValues: () => { party: PartyDto; publicID: string };
  setPartyData: (party: PartyDto) => void;
  hasPrevStep: boolean;
};

const OnboardingContext = createContext<OnboardingContextType>(
  {} as OnboardingContextType,
);

export const OnboardingContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [step, setStep] = useState<OnboardingSteps>(
    OnboardingSteps.PersonalDocument,
  );
  const formValues = useRef<Partial<FormModel>>({});
  const partyApiData = useRef<PartyDto>();

  const nextStep = () => {
    if (step === OnboardingSteps.LastStep) {
      return;
    }

    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(s => Math.max(0, s - 1));
  };

  const getFormValues: GetFormValuesInterface = step =>
    formValues.current[step];
  const setFormValues = (step: OnboardingSteps, values: unknown) => {
    formValues.current = {
      ...formValues.current,
      [step]: values,
    };
  };

  const getPartyValues = () => {
    return {
      party: getPartyDataFromForm(
        formValues.current as FormModel,
        partyApiData.current as PartyDto,
      ),
      publicID: partyApiData.current?.publicID ?? "",
    };
  };

  const setPartyData = (party: PartyDto) => {
    partyApiData.current = party;
  };

  return (
    <OnboardingContext.Provider
      value={{
        step,
        nextStep,
        prevStep,
        getFormValues,
        setFormValues,
        getPartyValues,
        setPartyData,
        hasPrevStep: step > OnboardingSteps.PersonalDocument,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);
