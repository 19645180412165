import { SVGProps } from "react";

export const ShoppingCartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 2C0.894531 2 0 2.89453 0 4C0 5.10547 0.894531 6 2 6C3.10547 6 4 5.10547 4 4H8.65625C10.7422 4 11.543 4.66797 12.2188 6.9375L20.2188 39.2188C20.6445 40.8281 21.125 42.6094 22.9375 43.4688C22.3672 44.1602 22 45.0352 22 46C22 48.207 23.793 50 26 50C28.207 50 30 48.207 30 46C30 45.2695 29.7812 44.5898 29.4375 44H35.5625C35.2188 44.5898 35 45.2695 35 46C35 48.207 36.793 50 39 50C41.207 50 43 48.207 43 46C43 44.9609 42.5898 44.0234 41.9375 43.3125C41.9727 43.2109 42 43.1133 42 43C42 42.4492 41.5508 42 41 42H25.7188C23.0234 42 22.7305 40.9219 22.1562 38.75L21.4688 36H39.8125C40.2266 36 40.6055 35.7305 40.75 35.3438L47.9375 16.3438C48.0547 16.0391 47.9961 15.707 47.8125 15.4375C47.6289 15.168 47.3242 15 47 15H16.2812L14.1562 6.40625C13.4766 4.11719 12.3203 2 8.65625 2H2ZM26 44C27.1016 44 28 44.8984 28 46C28 47.1016 27.1016 48 26 48C24.8984 48 24 47.1016 24 46C24 44.8984 24.8984 44 26 44ZM39 44C40.1016 44 41 44.8984 41 46C41 47.1016 40.1016 48 39 48C37.8984 48 37 47.1016 37 46C37 44.8984 37.8984 44 39 44Z"
      fill="currentColor"
    />
  </svg>
);
