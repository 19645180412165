import { Box, Skeleton } from "@mui/material";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { BlDivider } from "Components/Shared/BlDivider";
import { Fragment } from "react";

export const ConnectedAccountsSkeleton = () => (
  <PrimaryPaper sx={{ py: 4 }}>
    <Box mb={4}>
      <Skeleton variant="text" width={150} height={28} />
    </Box>
    <Box display="flex" flexDirection="column" gap={2}>
      {[1, 2].map((_, index) => (
        <Fragment key={index}>
          {index !== 0 && <BlDivider marginY={1} />}
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={3}>
              <Skeleton variant="rounded" width={48} height={24} />
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Skeleton variant="text" width={120} height={18} />
                <Skeleton variant="text" width={80} height={16} />
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Skeleton variant="text" width={100} height={20} />
            </Box>
          </Box>
        </Fragment>
      ))}
    </Box>
  </PrimaryPaper>
);
