import { useQuery } from "@tanstack/react-query";
import { GetUserListQuery, postUserList } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

export const USERS_LIST_QUERY_KEY = "USERS_LIST";

export const useUsersListQuery = (paginationParams: GetUserListQuery) =>
  useQuery({
    queryKey: [USERS_LIST_QUERY_KEY, paginationParams],
    queryFn: processResponse(() => postUserList(paginationParams)),
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // Cache stays fresh for 5 minutes
  });
