import { z } from "zod";
import { Resources } from "Translations/Resources";
import {
  AddressType,
  BankVerificationStatus,
  IdentityVerificationStatus,
  AdditionalInfoStatus,
  RiskProfileStatus,
  AverageTransactionValue,
  ExpectedMonthlyRevenue,
  BusinessActivity,
  SourceOfIncome,
  PartyType,
  UserStatus,
  Gender,
} from "Infrastructure/Api/Api";
import { PersonalIdentification } from "Utils/PersonalIdentification";

export const userValidationSchema = z.object({
  userID: z.number(),
  partyPublicID: z.string(),
  login: z.string().email("Login není validní"),
  status: z.nativeEnum(UserStatus),
  hasIdentificationsUploaded: z.boolean(),
  verificationAdditionalInfoStatus: z.nativeEnum(AdditionalInfoStatus),
  verificationRiskProfile: z.nativeEnum(RiskProfileStatus),
  verificationIdentityVerificationStatus: z.nativeEnum(
    IdentityVerificationStatus,
  ),
  verificationBankVerificationStatus: z.nativeEnum(BankVerificationStatus),
  verificationDailyBuyLimit: z.number(),
  verificationMonthlyBuyLimit: z.number(),
});

export const verificationValidationSchema = z.object({
  publicID: z.string(),
  customUserID: z.number(),
  businessActivity: z.nativeEnum(BusinessActivity).nullable(),
  otherBusinessActivityDetails: z.string().nullable(),
  sourceOfIncome: z.nativeEnum(SourceOfIncome).nullable(),
  otherSourceOfIncomeDetails: z.string().nullable(),
  expectedMonthlyRevenue: z.nativeEnum(ExpectedMonthlyRevenue).nullable(),
  averageTransactionValue: z.nativeEnum(AverageTransactionValue).nullable(),
  additionalInfoStatus: z.nativeEnum(AdditionalInfoStatus).nullable(),
  riskProfile: z.nativeEnum(RiskProfileStatus).nullable(),
  isPoliticallyExposedPerson: z.boolean(),
  identityVerificationStatus: z
    .nativeEnum(IdentityVerificationStatus)
    .nullable(),
  bankVerificationStatus: z.nativeEnum(BankVerificationStatus).nullable(),
  verificationLevel: z.number(),
  taxResidencyCountryID: z.number().nullable(),
});

const addressValidationSchema = z.object({
  addressID: z.number(),
  type: z.nativeEnum(AddressType),
  street: z.string().nullable(),
  streetNumber: z.string().nullable(),
  orientationNumber: z.string().nullable(),
  postalCode: z.string().min(1, "PSČ je povinné"),
  municipality: z.string().min(1, "Obec je povinná"),
});

export const createPartyValidationSchema = (type: PartyType) => {
  const baseSchema = {
    publicID: z.string(),
    phone: z
      .string()
      .min(12, "Prosím, zadejte platné telefonní číslo")
      .nullable(),
    type: z.nativeEnum(PartyType),
    email: z.string().email("E-mail není validní").nullable(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    companyName: z.string().nullable(),
    taxNumber: z.string().nullable(),
    companyNumber: z.string().nullable(),
    personalNumber: z.string(), //.nullable(),
    birthDate: z.coerce.date().or(z.string()).nullable(),
    gender: z.nativeEnum(Gender),
    birthPlace: z.string().nullable(),
    addresses: z.array(addressValidationSchema),
    nationalityCountryID: z.number(),
    identification: z.string().nullable(), //TODO review
    identificationNumber: z.string().nullable(),
    identificationValidFrom: z.coerce.date().or(z.string()).nullable(),
    identificationValidTo: z.coerce.date().or(z.string()).nullable(),
    identificationIssuer: z.string().nullable(),
  };

  if (type === PartyType.NaturalPerson) {
    return z
      .object({
        ...baseSchema,
        firstName: z.string().min(1, "Jméno je povinné"),
        email: z.string().email("E-mail není validní"),
      })
      .superRefine((obj, ctx) => {
        const personalIdentificator = PersonalIdentification.getInfo(
          obj.personalNumber,
        );

        if (!personalIdentificator.IsValid) {
          ctx.addIssue({
            code: "custom",
            message: "Neplatné rodné číslo",
            path: ["personalNumber"],
          });
        }
      });
  }

  if (type === PartyType.LegalEntity) {
    return z.object({
      ...baseSchema,
      companyName: z.string().min(1, "Název společnosti je povinný"),
      companyNumber: z.string().min(1, "IČO je povinné"),
      email: z.string().email("E-mail není validní"),
    });
  }

  return z.object(baseSchema);
};

export const createBeneficialOwnerValidationSchema = (
  t: (key: string) => string,
) => {
  return z.object({
    publicID: z.string(),
    type: z
      .nativeEnum(PartyType)
      .refine(val => val === PartyType.NaturalPerson),
    firstName: z.string().min(1, t(Resources.Validation.Required)),
    lastName: z.string().min(1, t(Resources.Validation.Required)),
    birthDate: z.coerce.date().or(z.string().min(1, t(Resources.Validation.Required))),
    nationalityCountryID: z.number(),
    addresses: z.array(addressValidationSchema),
  });
};
