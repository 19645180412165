import { Skeleton } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAsync } from "State/Balance/BalanceReducer";
import { NoBankAccount } from "Components/Balance/NoBankAccount";
import type { RootStateType } from "State/Store";
import type { AppUser } from "State/Auth/Models/AuthStateModels";
import { BalanceActions } from "Components/Balance/BalanceActions";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { Resources, useResource } from "Translations/Resources";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { AccountBalance } from "Components/Shared/AccountBalance";
import { PaperTitle } from "Components/Shared/PaperTitle";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import { CommonError } from "Components/Shared/Errors/CommonError";

const BalanceResources = Resources.Balance.BalanceContent;

type LayoutProps = { children: ReactNode };

const Layout = ({ children }: LayoutProps) => {
  const { t } = useResource();

  return (
    <PrimaryPaper>
      <PaperTitle>{t(BalanceResources.Title)}</PaperTitle>
      {children}
    </PrimaryPaper>
  );
};

type InnerContentProps = {
  user: AppUser;
  isLoading: boolean;
  isError?: boolean;
  accounts?: Array<BankAccountListItem>;
};

const InternalContent = ({
  user,
  isLoading,
  accounts,
  isError,
}: InnerContentProps) => {
  if (isLoading) {
    return (
      <Layout>
        <Skeleton height={70} width="60%" />
        <Skeleton height={50} width="90%" />
      </Layout>
    );
  }

  if (isError) {
    return (
      <Layout>
        <CommonError withoutPaper />
      </Layout>
    );
  }

  if (!accounts?.length) {
    return <NoBankAccount />;
  }

  const sortedAccounts = accounts.sort((a, b) => b.rank - a.rank);
  const firstAccount = sortedAccounts[0];

  return (
    <Layout>
      <AccountBalance balance={firstAccount.balance} bank={firstAccount.bank} />
      <BalanceActions user={user} publicID={firstAccount.publicID ?? ""} />
    </Layout>
  );
};

export const BalanceComponent = () => {
  const { data: accounts, isLoading, isLoadingError } = useBankingAccounts();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBalanceAsync.request());
  }, [dispatch]);

  const { user } = useSelector(({ auth }: RootStateType) => auth);

  if (!user) {
    return null;
  }

  return (
    <InternalContent
      user={user}
      accounts={accounts?.items}
      isLoading={isLoading}
      isError={isLoadingError}
    />
  );
};
