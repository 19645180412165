import { Box } from "@mui/material";
import { useTransactionsDetail } from "Api/Queries/Transactions/useTransactionsDetail";
import { useTransactionsStatus } from "Api/Queries/Transactions/useTransactionsStatus";
import { PaymentStatus } from "Components/Merchant/InitiatePaymentDetail/PaymentStatus";
import { QrCodePayment } from "Components/Shared/Payments/QrCodePayment";
import { FC, useEffect, useState } from "react";
import { getPaymentLinkDescriptor } from "Utils/BankUtils";

type Props = {
  publicID: string;
};

export const PaymentDetail: FC<Props> = ({ publicID }) => {
  const { data: transactionsDetail, isLoading: isLoadingTransactionsDetail } =
    useTransactionsDetail(publicID);
  const { data: status, isLoading: isLoadingStatus } =
    useTransactionsStatus(publicID);
  const [paymentDescriptor, setPaymentDescriptor] = useState<string>();

  useEffect(() => {
    if (transactionsDetail?.publicID) {
      setPaymentDescriptor(
        getPaymentLinkDescriptor(transactionsDetail.publicID),
      );
    }
  }, [transactionsDetail?.publicID]);

  const transactionStatus =
    status?.transactionStatus ?? transactionsDetail?.transactionStatus;
  const paymentInstructionStatus =
    status?.paymentInstructionStatus ??
    transactionsDetail?.paymentInstructionStatus;

  return (
    <>
      <QrCodePayment
        iban={transactionsDetail?.creditorIBAN}
        isLoading={isLoadingTransactionsDetail}
        amount={transactionsDetail?.amount}
        paymentReference={transactionsDetail?.paymentReference ?? undefined}
        paymentCode={transactionsDetail?.publicID}
        note={transactionsDetail?.noteForDebtor ?? undefined}
        paymentDescriptor={paymentDescriptor}
      />

      <Box marginTop={10} textAlign="center">
        <PaymentStatus
          transactionStatus={transactionStatus}
          paymentInstructionStatus={paymentInstructionStatus ?? undefined}
          isLoading={
            isLoadingStatus && !transactionStatus && !paymentInstructionStatus
          }
        />
      </Box>
    </>
  );
};
