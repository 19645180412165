import { useCreatePartyMutation } from "Api/Mutations/Party/useCreatePartyMutation";
import { useCreatePartyRelationMutation } from "Api/Mutations/Party/useCreatePartyRelationMutation";
import { useReplacePartyRelationMutation } from "Api/Mutations/Party/useReplacePartyRelationMutation";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { RelatedPartyDto } from "Infrastructure/Api/Api";
import { useEffect, useRef } from "react";

type Props = {
  legalEntityPartyID?: string;
  partyPublicID?: string;
  relatedPartyPublicID?: string;
  onSuccessUpdate?: (data: {
    relatedPartyPublicID: string;
    partyPublicID: string;
  }) => void;
};

export const useRelatedParty = ({
  legalEntityPartyID,
  partyPublicID: defaultPartyPublicID,
  relatedPartyPublicID: defaultRelatedPartyPublicID,
  onSuccessUpdate,
}: Props) => {
  const user = useLoggedInUser();

  const partyPublicID = useRef(defaultPartyPublicID);
  const relatedPartyPublicID = useRef(defaultRelatedPartyPublicID);

  useEffect(() => {
    partyPublicID.current = defaultPartyPublicID;
  }, [defaultPartyPublicID]);

  useEffect(() => {
    relatedPartyPublicID.current = defaultRelatedPartyPublicID;
  }, [defaultRelatedPartyPublicID]);

  const { mutate: putPartyRelation, isPending: isPartyRelationUpdating } =
    useReplacePartyRelationMutation({
      onSuccess: () =>
        onSuccessUpdate?.({
          relatedPartyPublicID: relatedPartyPublicID.current!,
          partyPublicID: partyPublicID.current!,
        }),
    });
  const { mutate: postPartyRelation, isPending: isPartyRelationCreating } =
    useCreatePartyRelationMutation({
      onSuccess: res =>
        onSuccessUpdate?.({
          relatedPartyPublicID: res.relatedPartyPublicID,
          partyPublicID: partyPublicID.current!,
        }),
    });
  const { mutateAsync: postParty, isPending: isPartyCreating } =
    useCreatePartyMutation();

  const updateRelatedParty = async (
    partyData: Omit<RelatedPartyDto, "relatedPartyPublicID">,
  ) => {
    if (relatedPartyPublicID.current && partyPublicID.current) {
      putPartyRelation({
        partyPublicID: relatedPartyPublicID.current,
        data: {
          relatedPartyPublicID: partyPublicID.current,
          ...partyData,
        },
      });
    } else {
      const party = await postParty({});
      partyPublicID.current = party.publicID;

      postPartyRelation({
        partyPublicID: legalEntityPartyID ?? user?.partyPublicID!,
        data: {
          relatedPartyPublicID: partyPublicID.current,
          ...partyData,
        },
      });
    }
  };

  return {
    isPending:
      isPartyRelationUpdating || isPartyRelationCreating || isPartyCreating,
    updateRelatedParty,
  };
};
