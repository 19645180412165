import { Skeleton, Stack } from "@mui/material";
import { useBanksEnvironment } from "Api/Queries/Banks/useBanksEnvironments";
import { BankButton } from "Components/Onboarding/BusinessEntity/Components/BanksList/BankButton/BankButton";
import { BankEnvironment } from "Infrastructure/Api/Api";
import { isSandbox } from "Utils/BankUtils";

type BanksListProps = {
  onSelect: (env: BankEnvironment) => void;
  pendingEnvironment?: BankEnvironment;
};

export const BanksList = ({ onSelect, pendingEnvironment }: BanksListProps) => {
  const { data, isLoading } = useBanksEnvironment();

  if (isLoading) {
    return <BankButtonSkeleton />;
  }

  if (data) {
    const banks = data.items?.filter(({ isVisible }) => isVisible) || [];

    return (
      <Stack gap={4}>
        {banks.map(({ bank, name, isEnabled, bankEnvironment }) => (
          <BankButton
            key={`${bank}-${bankEnvironment}`}
            bank={bank}
            isSandbox={isSandbox(name)}
            onClick={() => onSelect(bankEnvironment)}
            isLoading={pendingEnvironment === bankEnvironment}
            isDisabled={!isEnabled}
          />
        ))}
      </Stack>
    );
  }

  return <>Omlouváme se, nabídku bank se nepodařilo načíst.</>;
};

const BankButtonSkeleton = () => (
  <>
    <Skeleton variant="rounded" height={60} />
    <Skeleton variant="rounded" height={60} />
    <Skeleton variant="rounded" height={60} />
    <Skeleton variant="rounded" height={60} />
    <Skeleton variant="rounded" height={60} />
    <Skeleton variant="rounded" height={60} />
  </>
);
