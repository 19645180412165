import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { PaymentActionsProps } from "Components/Dashboard/BalanceWithPaymentActions/types";
import { FC } from "react";
import { Resources, useResource } from "Translations/Resources";
import { getAppUrl } from "Utils/UrlUtils";

const BalanceResources = Resources.Balance.BalanceContent;

type BalanceContainerProps = {
  renderBalance: FC<{ balance: number }>;
  renderActions: FC<PaymentActionsProps>;
  renderLoading: FC;
  renderError: FC;
};

export const BalanceContainer = ({
  renderBalance: Balance,
  renderActions: Actions,
  renderLoading: Loading,
  renderError: Error,
}: BalanceContainerProps) => {
  const { t } = useResource();
  const { data: { items } = {}, isLoading, isError } = useBankingAccounts();

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  const sortedAccounts = items?.sort((a, b) => b.rank - a.rank);
  const firstAccount = sortedAccounts?.[0];
  const totalAvailableFunds =
    sortedAccounts?.reduce((sum, { balance }) => sum + (balance ?? 0), 0) ?? 0;

  const appUrl = getAppUrl();
  const accountID = firstAccount?.publicID;
  const payMeLink = `${appUrl("customer-payment-pay")}?publicID=${accountID}`;
  const sendPaymentLink = `${appUrl("initiate-payment")}?publicID=${accountID}`;

  return (
    <>
      <Balance balance={totalAvailableFunds} />
      <Actions
        isDisabled={!accountID}
        payMeAction={{
          text: t(BalanceResources.PayAction),
          link: payMeLink,
        }}
        sendPaymentAction={{
          text: t(BalanceResources.SendPaymentAction),
          link: sendPaymentLink,
        }}
      />
    </>
  );
};
