import { GetUserListQuery } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { EntityListOfUserDto, postUserList } from "Infrastructure/Api/Api";

export const getUserListAsync = createAsyncAction(
  "@user/LIST_REQUEST",
  "@user/LIST_SUCCESS",
  "@user/LIST_FAILURE",
)<GetUserListQuery, EntityListOfUserDto, Error>();

function* getUserList(
  action: ReturnType<typeof getUserListAsync.request>,
): Generator {
  try {
    const response = yield* call(postUserList, action.payload);
    if (response.status === 200) {
      yield put(getUserListAsync.success(response.data));
    } else {
      yield put(
        getUserListAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getUserListAsync.failure(err as Error));
  }
}
export function* getUserListSaga() {
  yield takeLatest(getType(getUserListAsync.request), getUserList);
}
