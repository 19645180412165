import { Box, Skeleton } from "@mui/material";
import type { FormModel } from "Components/Merchant/Initiate/MerchantInitiateComponent";
import { BankingAccountSelector } from "Components/Shared/Inputs/Form/BankingAccountSelector";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";

const FormResources = Resources.Merchant.Initiate.Form;

const options = [
  { code: "bank", name: "Bankovní účet" },
  { code: "iban", name: "IBAN" },
];

type Props = {
  bankingAccounts?: BankAccountListItem[];
  isLoading?: boolean;
};

export const FormFields: FC<Props> = ({ bankingAccounts, isLoading }) => {
  const { t } = useResource();
  const { control, watch } = useFormContext<FormModel>();

  const accountType = watch("accountType");

  if (isLoading && !bankingAccounts) {
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <Skeleton height={140} variant="rectangular" />
        <Skeleton height={140} variant="rectangular" />
        <Skeleton height={80} variant="rectangular" />
      </Box>
    );
  }

  return (
    <div>
      <RadioButtons
        label="Typ účtu pro převod"
        control={control}
        codeList={options}
        name="accountType"
      />

      {accountType === "bank" ? (
        <BankingAccountSelector
          name="bankAccountID"
          control={control}
          label={t(FormResources.AccountSelectTitle)}
          bankAccounts={bankingAccounts ?? []}
        />
      ) : (
        <FormInput
          control={control}
          name="iban"
          label="IBAN příjemce"
          mask={/^[A-Za-z0-9 ]+$/}
          valueTransformer={v => v?.toUpperCase()}
          type="text"
        />
      )}

      <FormInput control={control} name="amount" label="Částka" type="number" />
    </div>
  );
};
