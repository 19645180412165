import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { useAverageTransactionValueList } from "Hooks/Options/useAverageTransactionValueList";
import { Control, FieldPath, FieldValues } from "react-hook-form";

type Props<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  label?: string;
};

export const AverageTransactionValueDropdown = <FormType extends FieldValues>({
  control,
  name,
  label,
}: Props<FormType>) => {
  const codeList = useAverageTransactionValueList();

  return (
    <Dropdown codeList={codeList} name={name} control={control} label={label} />
  );
};
