import { Box } from "@mui/material";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { formatCurrency } from "Utils/CurrencyUtils";
import { MobileBalanceSkeleton } from "./Components/MobileBalanceSkeleton";
import { PaymentButtons } from "Components/Dashboard/MobileBalance/Components/PaymentButtons";
import { BalanceDisplay } from "Components/Dashboard/MobileBalance/Components/BalanceDisplay";

export const MobileBalance = () => {
  const {
    data: { items: accounts } = {},
    isLoading,
    isLoadingError,
  } = useBankingAccounts();

  if (isLoading) {
    return <MobileBalanceSkeleton />;
  }

  if (!accounts || isLoadingError) {
    return <MobileBalanceSkeleton isError />;
  }

  const sortedAccounts = accounts.sort((a, b) => b.rank - a.rank);
  const firstAccount = sortedAccounts[0];
  const totalAvailableFunds = accounts.reduce(
    (sum, { balance }) => sum + (balance ?? 0),
    0,
  );
  const formattedTotalAvailableFunds = formatCurrency(
    totalAvailableFunds,
    undefined,
    undefined,
    undefined,
    true,
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      color="white"
      gap={12}
      pb={6}
    >
      <BalanceDisplay totalAvailableFunds={formattedTotalAvailableFunds} />
      <PaymentButtons firstAccountPublicID={firstAccount?.publicID} />
    </Box>
  );
};
