import { Control } from "react-hook-form";
import { useCodeListQuery } from "Api/Queries/CodeList/useCodeListQuery";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { RadioButtonsSkeleton } from "Components/Onboarding/BusinessEntity/Components/CountrySelector/RadioButtonsSkeleton";
import { LicenseSelectionModel } from "Components/Onboarding/BusinessEntity/types";
import { Typography } from "@mui/material";

type CountrySelectorProps = {
  control: Control<LicenseSelectionModel>;
};

export const CountrySelector = ({ control }: CountrySelectorProps) => {
  const { data: { countries } = {}, isLoading, isError } = useCodeListQuery();

  if (isLoading) {
    return <RadioButtonsSkeleton />;
  }

  if (isError || !countries) {
    return (
      <Typography color="error.main">Seznam zemí není k dispozici.</Typography>
    );
  }

  const countryOptions = countries
    .filter(({ alpha2Code }) => alpha2Code === "CZ" || alpha2Code === "SK") //TODO: we support only cz and sk, maybe list should come already filtered from BE?
    .map(({ id, name, alpha2Code }) => ({
      id,
      name,
      code: alpha2Code,
    }));

  return (
    <RadioButtons
      row
      name="country"
      label="Vyberte zemi, ve které si chcete licenci koupit."
      control={control}
      codeList={countryOptions}
    />
  );
};
