import { useOCR } from "Api/Mutations/Onboarding/useOCR";
import { useCreatePartyRelationMutation } from "Api/Mutations/Party/useCreatePartyRelationMutation";
import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { useUpdateVerificationsMutation } from "Api/Mutations/Verifications/useUpdateVerificationsMutation";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { useVerificationsQuery } from "Api/Queries/Verifications/useVerificationsQuery";
import { useRelatedParty } from "Components/Onboarding/BusinessEntity/Hooks/useRelatedParty";
import {
  FormModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { getMappedAresToForm } from "Components/Onboarding/BusinessEntity/Utils/aresUtils";
import { getFormDataFromOCR } from "Components/Onboarding/BusinessEntity/Utils/ocrUtils";
import {
  getBusinessPartyDataFromForm,
  getInitialFormDataFromParty,
  getPartyDataFromForm,
} from "Components/Onboarding/BusinessEntity/Utils/partyUtils";
import { getFormDataFromVerifications } from "Components/Onboarding/BusinessEntity/Utils/verificationUtils";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { PartyDto, PartyType, RelationType } from "Infrastructure/Api/Api";
import { logError } from "Infrastructure/Utils/LoggingUtils";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { toast } from "sonner";
import { DeepPartial } from "types";
import { appUrl } from "Utils/UrlUtils";

type GetFormValues = <T extends OnboardingSteps>(
  step: T,
) => Partial<FormModel[T]>;

type ActingPersonParty = {
  publicID?: string;
  relatedPublicID?: string;
};

type OnboardingContextType = {
  step: OnboardingSteps;
  nextStep: (values: unknown) => void;
  prevStep: () => void;
  setFormValues: (step: OnboardingSteps, values: unknown) => void;
  getFormValues: GetFormValues;
  setAresData: (aresData: PartyDto | null) => void;
  hasPrevStep: boolean;
  isLoadingNextStep: boolean;
  isLoadingOnboarding: boolean;
};

const OnboardingContext = createContext<OnboardingContextType>(
  {} as OnboardingContextType,
);

type OnboardingContextProviderProps = {
  children: ReactNode;
  isHunter: boolean;
};

export const OnboardingContextProvider = ({
  children,
  isHunter = false,
}: OnboardingContextProviderProps) => {
  const [step, setStep] = useState<OnboardingSteps>(0); // OnboardingSteps.BusinessInformation,
  const [initialLoading, setInitialLoading] = useState(true);
  console.log("initialLoading: ", initialLoading);
  const [actingPersonParty, setActingPersonParty] =
    useState<ActingPersonParty>();

  const actingPersonIsOwner = useRef(false);
  const formValues = useRef<DeepPartial<FormModel>>({
    [OnboardingSteps.AdditionalInformation]: {
      isOwner: true,
    },
  });

  const user = useLoggedInUser();
  const navigate = useNavigate();

  const { data: partyData } = usePartyQuery(user?.partyPublicID);
  const { data: verificationData } = useVerificationsQuery();
  const { data: actingPersonPartyData, isLoading: isLoadingActingPersonParty } =
    usePartyQuery(actingPersonParty?.publicID);

  useEffect(
    function loadInitialFormValues() {
      if (!initialLoading) {
        return;
      }
      console.log("isHunter: ", isHunter);

      // replace only if the form is empty
      if (partyData) {
        const { formData, actingPerson } = getInitialFormDataFromParty(
          partyData,
          formValues.current ?? {},
        );
        formValues.current = formData;

        setActingPersonParty({
          relatedPublicID: actingPerson.relatedPartyPublicID,
          publicID: actingPerson.partyPublicID,
        });

        if (actingPerson?.isOwner) {
          actingPersonIsOwner.current = true;
        }
      }

      if (verificationData) {
        formValues.current = getFormDataFromVerifications(
          verificationData,
          formValues.current ?? {},
        );
      }

      if (verificationData && partyData) {
        setInitialLoading(false);
      }
    },
    [partyData, verificationData, initialLoading],
  );

  const { mutate: putParty, isPending: isPartyMutating } = usePartyMutation({
    onSuccess: () => {
      const isLegalEntity =
        formValues.current[OnboardingSteps.BusinessInformation]?.partyType ===
        PartyType.LegalEntity;

      if (step === OnboardingSteps.UserDetail && !isLegalEntity) {
        // When type is not legal entity, we need to skip additional information step
        const nextStep = isHunter
          ? OnboardingSteps.LicenseSelection
          : OnboardingSteps.BankAccountConnection;
        setStep(nextStep);
      } else {
        // const nextStep = isHunter ? OnboardingSteps.LicenseSelection : step + 1;
        setStep(step + 1);
      }
    },
  });

  const { updateRelatedParty, isPending: isRelatedPartyUpdating } =
    useRelatedParty({
      legalEntityPartyID: user?.partyPublicID!,
      partyPublicID: actingPersonParty?.publicID,
      relatedPartyPublicID: actingPersonParty?.relatedPublicID,
      onSuccessUpdate: ({ relatedPartyPublicID, partyPublicID }) => {
        setActingPersonParty({
          publicID: partyPublicID,
          relatedPublicID: relatedPartyPublicID,
        });
        // we can skip documents upload if we have already identification number (the user document was already uploaded)
        if (actingPersonPartyData?.identificationNumber) {
          formValues.current = getFormDataFromOCR(
            actingPersonPartyData,
            formValues.current ?? {},
          );
          setStep(OnboardingSteps.UserDetail);
        } else {
          setStep(OnboardingSteps.PersonalDocument);
        }
      },
    });

  const { mutate: postPartyRelation, isPending: isPartyRelationCreating } =
    useCreatePartyRelationMutation({
      onSuccess: () => {
        actingPersonIsOwner.current = true;
        if (isHunter) {
          setStep(OnboardingSteps.LicenseSelection);
        } else {
          setStep(step + 1);
        }
      },
    });

  const { mutate: putVerification, isPending: isVerificationMutating } =
    useUpdateVerificationsMutation({
      onSuccess: () => {
        if (
          formValues.current[OnboardingSteps.BusinessInformation]?.partyType !==
          PartyType.LegalEntity
        ) {
          setStep(step + 1);
          return;
        }

        updateRelatedParty({
          relationType:
            formValues.current[OnboardingSteps.IncomeInformation]
              ?.relationType!,
        });
      },
    });

  const { mutateAsync: ocrRequest, isPending: isProcessingOcr } = useOCR({
    onSuccess: ({ data }) => {
      if (!data?.publicID) {
        toast.error("Chyba při zpracování dokumentů");
        logError(new Error("OCR failed - publicID missing"));
        return;
      }

      if (data) {
        formValues.current = getFormDataFromOCR(data, formValues.current ?? {});
        setStep(step + 1);
      }
    },
  });

  const isLoadingNextStep =
    isPartyMutating ||
    isVerificationMutating ||
    isLoadingActingPersonParty ||
    isRelatedPartyUpdating ||
    isPartyRelationCreating ||
    isProcessingOcr;

  const getFormValues: GetFormValues = step => formValues.current[step] || {};
  const setFormValues = (step: OnboardingSteps, values: unknown = {}) => {
    formValues.current = {
      ...formValues.current,
      [step]: values,
    };
  };

  const nextStep = (values: unknown) => {
    if (isLoadingNextStep) {
      return;
    }

    setFormValues(step, values);

    if (step === OnboardingSteps.BusinessInformation) {
      putParty({
        publicID: user?.partyPublicID!,
        data: getBusinessPartyDataFromForm(formValues.current, partyData!),
      });
    } else if (step === OnboardingSteps.IncomeInformation) {
      const {
        businessActivity,
        otherBusinessActivityDetails,
        averageTransactionValue,
        expectedMonthlyRevenue,
        incomeSource,
        isPoliticallyExposed,
        taxResidence,
        incomeSourceDetail,
      } = formValues.current[OnboardingSteps.IncomeInformation] ?? {};
      putVerification({
        businessActivity,
        otherBusinessActivityDetails,
        averageTransactionValue,
        expectedMonthlyRevenue,
        taxResidencyCountryID: taxResidence,
        sourceOfIncome: incomeSource,
        otherSourceOfIncomeDetails: incomeSourceDetail,
        isPoliticallyExposedPerson: !!isPoliticallyExposed,
      });
    } else if (step === OnboardingSteps.ManualAccountVerification) {
      navigate(appUrl("dashboard"));
    } else if (step === OnboardingSteps.PersonalDocument) {
      const documents = formValues.current[OnboardingSteps.PersonalDocument];

      ocrRequest({
        documents: {
          firstIDCardFrontSide: documents!.firstDocument!.frontSide as File,
          firstIDCardBackSide: documents!.firstDocument!.backSide as File,
          secondIDCardFrontSide: documents!.secondDocument!.frontSide as File,
        },
        partyPublicID:
          actingPersonParty?.publicID || (user!.partyPublicID as string),
      });
    } else if (step === OnboardingSteps.UserDetail) {
      putParty({
        publicID: actingPersonParty?.publicID || user?.partyPublicID!,
        data: getPartyDataFromForm(
          formValues.current as FormModel,
          actingPersonPartyData ? actingPersonPartyData : partyData!,
        ),
      });
    } else if (step === OnboardingSteps.AdditionalInformation) {
      const nextStep = isHunter ? OnboardingSteps.LicenseSelection : step + 1;
      if (
        actingPersonParty?.publicID &&
        formValues.current[OnboardingSteps.AdditionalInformation]?.isOwner &&
        !actingPersonIsOwner.current
      ) {
        postPartyRelation({
          partyPublicID: user?.partyPublicID!,
          data: {
            relatedPartyPublicID: actingPersonParty.publicID,
            relationType: RelationType.BeneficialOwner,
          },
        });
      } else if (
        actingPersonIsOwner.current &&
        !formValues.current[OnboardingSteps.AdditionalInformation]?.isOwner
      ) {
        // TODO: remove relation for action person as owner
        setStep(nextStep);
      } else {
        setStep(nextStep);
      }
    } else if (step === OnboardingSteps.LicenseSelection) {
      console.log("TODO Uploading license data....", values);
      setTimeout(() => {
        console.log("TODO Uploading successful....", values);
        setStep(step + 1);
      }, 500);
    } else if (step === OnboardingSteps.LicensePayment) {
      console.log("TODO Execute payment....", values);
      // setTimeout(() => {
      console.log("TODO Payment successful....", values);
      // navigate(appUrl("dashboard"));
      // }, 500);
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    const isLegalEntity =
      formValues.current[OnboardingSteps.BusinessInformation]?.partyType ===
      PartyType.LegalEntity;

    let previousStep =
      step === OnboardingSteps.BankAccountConnection && !isLegalEntity
        ? OnboardingSteps.UserDetail
        : step - 1;

    // hunters need to skip bank account steps
    if (step === OnboardingSteps.LicenseSelection) {
      previousStep = isLegalEntity
        ? OnboardingSteps.AdditionalInformation
        : OnboardingSteps.UserDetail;
    }

    setStep(Math.max(0, previousStep));
  };

  const setAresData = (aresData: PartyDto | null) => {
    if (!aresData) {
      return;
    }

    formValues.current = getMappedAresToForm(aresData, formValues.current);
  };
  return (
    <OnboardingContext.Provider
      value={{
        step,
        nextStep,
        prevStep,
        getFormValues,
        setFormValues,
        setAresData,
        hasPrevStep: step > OnboardingSteps.BusinessInformation,
        isLoadingNextStep,
        isLoadingOnboarding: initialLoading,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);
