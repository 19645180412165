import { Box, Skeleton } from "@mui/material";

type PaymentActionSkeletonProps = {
  animation: "pulse" | false;
};

export const PaymentActionSkeleton = ({
  animation,
}: PaymentActionSkeletonProps) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Skeleton
      variant="circular"
      width={64}
      height={64}
      animation={animation}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      }}
    />
    <Skeleton
      variant="text"
      width={60}
      height={24}
      animation={animation}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      }}
    />
  </Box>
);
