import { zodResolver } from "@hookform/resolvers/zod";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { ValuesBox } from "Components/Onboarding/NaturalPerson/Components/ValuesBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";
import { BusinessDropdown } from "Components/Shared/SelectorsForm/BusinessDropdown";
import {
  BusinessInformationModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { useAres } from "Components/Onboarding/BusinessEntity/Hooks/useAres";
import { PartyDto, PartyType } from "Infrastructure/Api/Api";
import { Grid } from "@mui/material";
import { addressValidationSchema } from "Components/Onboarding/schemas";

export const validationSchema: z.ZodType<BusinessInformationModel> = z.object({
  partyType: z.nativeEnum(PartyType),
  identificationNumber: z
    .string()
    .regex(/^\d{8}$/, "IČO musí být číslo o délce 8 znaků."),
  companyName: z.string(),
  address: addressValidationSchema,
  contact: z.object({
    phone: z.string().min(12, "Prosím, zadejte platné telefonní číslo"),
  }),
});

export const BusinessInformationStep: FC = () => {
  const { t } = useResource();

  const { nextStep, getFormValues, setAresData } = useOnboardingContext();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<BusinessInformationModel>({
    defaultValues: getFormValues(OnboardingSteps.BusinessInformation),
    resolver: zodResolver(validationSchema),
  });

  const onAresData = (aresData: PartyDto | null) => {
    setAresData(aresData);
    reset(getFormValues(OnboardingSteps.BusinessInformation));
  };

  const { isFetchingAres } = useAres({
    initialIdentifierNumber: getFormValues(OnboardingSteps.BusinessInformation)
      ?.identificationNumber,
    partyType: watch("partyType"),
    identifierNumber: watch("identificationNumber"),
    onAresData,
    disabled: !isDirty,
  });

  const onSubmit = (data: BusinessInformationModel) => {
    nextStep(data);
  };

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox submitButtonLabel="Pokračovat" submitDisabled={isFetchingAres}>
        <ValuesBox title="Základní údaje" hideDivider>
          <BusinessDropdown
            control={control}
            name="partyType"
            label="Forma podnikání"
          />
          <FormInput
            control={control}
            name="identificationNumber"
            label="IČO"
            required
          />
          <FormInput
            control={control}
            name="companyName"
            label="Název subjektu"
            required
          />
        </ValuesBox>

        <ValuesBox title="Sídlo" hideDivider>
          <FormInput
            control={control}
            name="address.street"
            label="Ulice"
            required
          />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormInput
                control={control}
                name="address.descriptionNumber"
                label={t(Resources.Common.DescriptionNumber)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                control={control}
                name="address.orientationNumber"
                label={t(Resources.Common.OrientationNumber)}
              />
            </Grid>
          </Grid>
          <FormInput
            control={control}
            name="address.city"
            label="Město"
            required
          />
          <FormInput
            control={control}
            name="address.postalCode"
            label="PSČ"
            mask="000 00"
            required
          />
        </ValuesBox>

        <ValuesBox title="Kontakt" hideDivider>
          <FormInput
            control={control}
            name="contact.phone"
            label={t(Resources.Common.Phone)}
            mask="+000 000 000 000"
            inputProps={{
              inputMode: "tel",
            }}
          />
        </ValuesBox>
      </StepBox>
    </FullHeightForm>
  );
};
