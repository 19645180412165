import { IdentityVerificationStatus } from "Infrastructure/Api/Api";
import { CodeListItem } from "Models/ICodeListDto";
import { useMemo } from "react";
import { Resources, useResource } from "Translations/Resources";

type ListItem = CodeListItem<IdentityVerificationStatus>;
type Result = ListItem[];

export const useIdentityVerificationStatusList = () => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      Object.entries(IdentityVerificationStatus).map<ListItem>(([key]) => ({
        code: key as IdentityVerificationStatus,
        name: t(Resources.Form.IdentityVerification[key as IdentityVerificationStatus]),
      })),
    [t],
  );
};