import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { Box, Stack, Typography } from "@mui/material";
import { BankEnvironment } from "Infrastructure/Api/Api";
import { useBankingAuth } from "Hooks/useBankingAuth";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { BanksList } from "Components/Onboarding/BusinessEntity/Components/BanksList/BanksList";
import { BankButton } from "Components/Onboarding/BusinessEntity/Components/BanksList/BankButton/BankButton";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";
import { useVerificationsMutation } from "Api/Mutations/Verifications/useVerificationsMutation";
import { useDispatch } from "react-redux";

export const LicensePaymentStep = () => {
  const { nextStep } = useOnboardingContext();
  const dispatch = useDispatch();
  const { mutate, isPending: isVerificationsPending } =
    useVerificationsMutation({
      onSuccess: () => {
        dispatch(getUserInfoAsync.request(undefined));
      },
    });
  const { isPending, authorize, selectedEnvironment } = useBankingAuth(); //TODO: change for payment instead of authorize

  const onBankSelected = (environment: BankEnvironment | null = null) => {
    // authorize({ environment });
    console.log("TODO: Payment with ", environment);
    nextStep({ bank: environment });
    mutate();
  };

  const isLoading = isPending || isVerificationsPending;

  return (
    <FullHeightForm>
      <Box mb={5}>
        <Typography>
          K plné aktivaci licence je nutné provést její úhradu, která bude
          provedena převodem na účet. Vyberte banku, ze které chcete platbu
          provést.
        </Typography>
      </Box>
      <Stack gap={4}>
        <PaperTitle>Nabídka bank</PaperTitle>
        <BanksList
          onSelect={onBankSelected}
          pendingEnvironment={isLoading ? selectedEnvironment : undefined}
        />
        <BankButton bank="Unknown" onClick={onBankSelected} />
      </Stack>
    </FullHeightForm>
  );
};
