import { FormEvent } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserVerificationUpdateDto } from "Infrastructure/Api/Api";
import { verificationValidationSchema } from "../../schemas";
import { VerificationFormFields } from "Components/Users/UserDetailPageContent/Components/VerificationFormFields";
import { toast } from "sonner";
import { usePutVerificationsMutation } from "Api/Mutations/Verifications/useVerificationsMutation";
import { StyledForm } from "Components/Users/UserDetailPageContent/Components/StyledForm";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

type VerificationFormProps = {
  verification: UserVerificationUpdateDto;
};

export const VerificationForm = ({ verification }: VerificationFormProps) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<UserVerificationUpdateDto>({
    resolver: zodResolver(verificationValidationSchema),
    defaultValues: verification,
  });

  const { mutate: updateVerifications, isPending } =
    usePutVerificationsMutation({
      onSuccess: data => {
        reset(data);
        toast.success("Uložení údajů ověření proběhlo úspěšně");
      },
      onError: () =>
        toast.error("Při ukládání údajů ověření se vyskytla chyba"),
    });

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(data => {
      updateVerifications(data);
    })();
  };

  return (
    <>
      <StyledForm onSubmit={onSubmit}>
        <PrimaryPaper>
          <VerificationFormFields control={control} watch={watch} />
          <PrimaryButton
            fullWidth
            type="submit"
            disabled={!isDirty}
            isLoading={isPending}
            variant="contained"
            color="primary"
          >
            Uložit
          </PrimaryButton>
        </PrimaryPaper>
      </StyledForm>
    </>
  );
};
