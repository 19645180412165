import { Box, Typography } from "@mui/material";
import { BankImage } from "Components/Shared/BankImage";
import { Bank } from "Infrastructure/Api/Api";
import { getLocalBankAccountNumberFromIBAN } from "Utils/AccountUtils";
import { formatCurrency } from "Utils/CurrencyUtils";

type AccountProps = {
  bank: Bank;
  productName: string;
  iban: string;
  balance: number;
};

export const Account = ({ bank, productName, iban, balance }: AccountProps) => {
  const accountNumber = getLocalBankAccountNumberFromIBAN(iban);
  const accountBalance = formatCurrency(balance);
  
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center" gap={3}>
        <BankImage bank={bank} size="small" isRounded />
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography fontSize="0.875rem">{productName}</Typography>
          <Typography fontSize="0.75rem">{accountNumber}</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        {accountBalance}
      </Box>
    </Box>
  );
};
