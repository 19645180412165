import { useTransactions } from "Api/Queries/Transactions/useTransactions";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { CommonError } from "Components/Shared/Errors/CommonError";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { RecentTransactions } from "Components/Shared/Transactions/RecentTransactions";
import { useNavigate } from "react-router";
import { Resources, useResource } from "Translations/Resources";
import { appUrl } from "Utils/UrlUtils";

export const TransactionsComponent = () => {
  const { t } = useResource();
  const navigate = useNavigate();
  const {
    data: transactions,
    isLoading,
    isError,
  } = useTransactions({ limit: 3 });

  const transactionItems = transactions?.pages?.[0]?.items;

  if (!isError && transactionItems?.length === 0) {
    return null;
  }

  return (
    <>
      <PaperTitle>
        {t(Resources.Transactions.History)}
      </PaperTitle>
      {isError ? (
        <CommonError withoutPaper paddingY={0} />
      ) : (
        <>
          {!!transactionItems?.length && (
            <>
              <RecentTransactions
                transactions={transactionItems}
                isLoading={isLoading}
              />

              <StyledFlex justifyContent="center">
                <PrimaryButton
                  variant="text"
                  color="info"
                  onClick={() => navigate(appUrl("transactions"))}
                >
                  {t(Resources.Common.ShowAll)}
                </PrimaryButton>
              </StyledFlex>
            </>
          )}
        </>
      )}
    </>
  );
};
