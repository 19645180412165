import { Box, Skeleton, Typography } from "@mui/material";
import { InvoiceQRCode } from "Components/Invoices/InvoiceQRCode";
import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";

const Row = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Left = styled(Typography)`
  font-size: 16px;
`;

const Right = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  justify-self: flex-end;
  word-break: break-word;
  text-align: end;
`;

const LoadingRowWrapper: FC<PropsWithChildren<{ isLoading?: boolean }>> = ({
  children,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <Row>
        <Left>
          <Skeleton width="125px" />
        </Left>
        <Right>
          <Skeleton width="60px" />
        </Right>
      </Row>
    );
  }

  return <>{children}</>;
};

type Props = {
  isLoading?: boolean;
  iban?: string;
  amount?: number;
  accountNumber?: string;
  paymentReference?: string;
  note?: string;
  paymentCode?: string;
  paymentDescriptor?: string;
};

export const QrCodePayment: FC<Props> = ({
  isLoading,
  iban,
  accountNumber,
  paymentReference,
  note,
  paymentCode,
  paymentDescriptor,
  amount,
}) => {
  const { t } = useResource();

  return (
    <>
      <LoadingRowWrapper isLoading={isLoading}>
        {iban && (
          <Row>
            <Left>{t(Resources.Common.IBAN)}</Left>
            <Right>{iban}</Right>
          </Row>
        )}
      </LoadingRowWrapper>

      <LoadingRowWrapper isLoading={isLoading}>
        {accountNumber && (
          <Row>
            <Left>{t(Resources.Common.AccountNumber)}</Left>
            <Right>{accountNumber}</Right>
          </Row>
        )}
      </LoadingRowWrapper>

      <LoadingRowWrapper isLoading={isLoading}>
        {paymentReference && (
          <Row>
            <Left>{t(Resources.Common.VariableSymbol)}</Left>
            <Right>{paymentReference}</Right>
          </Row>
        )}
      </LoadingRowWrapper>

      <LoadingRowWrapper isLoading={isLoading}>
        {amount && (
          <Row>
            <Left>{t(Resources.Common.Amount)}</Left>
            <Right>{formatCurrency(amount)}</Right>
          </Row>
        )}
      </LoadingRowWrapper>

      <LoadingRowWrapper isLoading={isLoading}>
        {note && (
          <Row>
            <Left>{t(Resources.Common.Note)}</Left>
            <Right>{note}</Right>
          </Row>
        )}
      </LoadingRowWrapper>

      <LoadingRowWrapper isLoading={isLoading}>
        {paymentCode && (
          <Row>
            <Left>{t(Resources.Common.TransactionPaymentCode)}</Left>
            <Right>{paymentCode}</Right>
          </Row>
        )}
      </LoadingRowWrapper>

      <LoadingRowWrapper isLoading={isLoading}>
        {paymentDescriptor && (
          <Box marginTop={7}>
            <InvoiceQRCode paymentDescriptor={paymentDescriptor} size={200} />
          </Box>
        )}
      </LoadingRowWrapper>
    </>
  );
};
