import BigNumber from "bignumber.js";
import { CurrencyCode } from "Models/CurrencyCodes";

const satoshiConstant = new BigNumber(100000000);

export const satoshiToBitcoin = (value: number) =>
  new BigNumber(value).dividedBy(satoshiConstant).toNumber();

export const bitcoinToSatoshi = (value: number) => {
  return new BigNumber(value)
    .multipliedBy(satoshiConstant)
    .integerValue()
    .toNumber();
};

export const formatCurrency = (
  value: number | null | undefined,
  currencyCode: CurrencyCode | string | null | undefined = undefined,
  decimalPlaces = 2,
  asBitcoin = false,
  isNumberOnly = false,
) => {
  const locales = () => {
    switch (currencyCode) {
      case CurrencyCode.EUR:
        return "sk-SK";
      case CurrencyCode.CHF:
        return "fr-CH";
      case CurrencyCode.GBP:
        return "en-GB";
      case CurrencyCode.USD:
        return "en-US";
      case CurrencyCode.PLN:
        return "pl-PL";

      default:
        return "cs-CZ";
    }
  };

  if (currencyCode === CurrencyCode.BTC) {
    if (asBitcoin) {
      return new Intl.NumberFormat(locales(), {
        style: "currency",
        currency: "BTC",
        maximumFractionDigits: 8,
        minimumFractionDigits: 8,
      }).format(value ?? 0);
    }

    return new Intl.NumberFormat(locales(), {
      style: "currency",
      currency: "SAT",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(bitcoinToSatoshi(value ?? 0));
  }

  const formatter = new Intl.NumberFormat(locales(), {
    style: isNumberOnly ? "decimal" : "currency",
    currency: currencyCode ?? CurrencyCode.CZK,
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: 0,
  });
  return formatter.format(value ?? 0);
};

export const getCurrencySymbol = (currency: string) =>
  (0)
    .toLocaleString("cs-CZ", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();

export const formatNumberOnly = (sum: number) => {
  return new Intl.NumberFormat("cs-CZ", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(sum ?? 0);
};
