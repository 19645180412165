import { Box } from "@mui/material";

type AvailabilityInfoProps = {
  current: number;
  max: number;
};

export const AvailabilityInfo = ({ current, max }: AvailabilityInfoProps) => (
  <Box>
    Dostupnost: {current}/{max}
  </Box>
); 