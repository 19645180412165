import { LegalEntityFields } from "Components/Users/UserDetailPageContent/Components/PartyForm/LegalEntityFields";
import { NaturalPersonFields } from "Components/Users/UserDetailPageContent/Components/PartyForm/NaturalPersonFields";
import { SelfEmployedFields } from "Components/Users/UserDetailPageContent/Components/PartyForm/SelfEmployedFields";
import { PartyFormFieldsProps } from "Components/Users/UserDetailPageContent/Components/PartyForm/types";
import { PartyType } from "Infrastructure/Api/Api";
import { FC } from "react";

export const PARTY_TYPE_TO_FORM_FIELDS: Record<
  PartyType,
  FC<PartyFormFieldsProps>
> = {
  [PartyType.NaturalPerson]: NaturalPersonFields,
  [PartyType.LegalEntity]: LegalEntityFields,
  [PartyType.SelfEmployed]: SelfEmployedFields,
};
