import { Box, Typography } from "@mui/material";
import { SearchAccountIcon } from "Components/Shared/Icons";
import { FC } from "react";
import { Resources, useResource } from "Translations/Resources";

const StepResources = Resources.Onboarding.Steps.Documents;

export const ProcessingOcr: FC = () => {
  const { t } = useResource();

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box marginTop={12} marginBottom={2}>
        <SearchAccountIcon />
      </Box>
      <Typography variant="body2">{t(StepResources.Processing)}</Typography>
    </Box>
  );
};
