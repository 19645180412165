import { Box, Stack, Typography } from "@mui/material";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { BlPrimarySkeletonWrapper } from "Components/Shared/BlPrimarySkeletonWrapper";
import { BlTextButton } from "Components/Shared/Buttons/BlTextButton";
import { isValid } from "date-fns";
import { useCountryList } from "Hooks/Options/useCountryList";
import { useFormatLocale } from "Hooks/useFormatLocale";
import { AddressDto, AddressType } from "Infrastructure/Api/Api";
import { FC } from "react";
import { useTheme } from "styled-components";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  publicID: string;
  onEdit: () => void;
};

export const OwnerCard: FC<Props> = ({ publicID, onEdit }) => {
  const theme = useTheme();
  const { t } = useResource();
  const { countries } = useCountryList();
  const format = useFormatLocale();

  const { data: owner, isLoading } = usePartyQuery(publicID);

  const address = owner?.addresses.find(
    ({ type }) => type === AddressType.Permanent,
  );

  return (
    <BlPrimarySkeletonWrapper isLoading={isLoading}>
      <Stack
        paddingY={1}
        paddingX={2}
        border="1px solid"
        borderRadius={2}
        borderColor={theme.colors.border}
      >
        <Stack direction="row" justifyContent="space-between" gap={3}>
          <Typography variant="button">
            {owner?.firstName} {owner?.lastName}
          </Typography>
          <BlTextButton onClick={onEdit}>
            {t(Resources.Common.Edit)}
          </BlTextButton>
        </Stack>
        <Box>
          <Typography variant="body1" lineHeight="28px">
            {[
              owner?.birthDate && isValid(new Date(owner?.birthDate))
                ? format(new Date(owner?.birthDate), "d.M.yyyy")
                : undefined,
              address ? buildAddress(address) : undefined,
              countries.find(({ id }) => id === owner?.nationalityCountryID)
                ?.name,
            ]
              .filter(Boolean)
              .join(" • ")}
          </Typography>
        </Box>
      </Stack>
    </BlPrimarySkeletonWrapper>
  );
};

const buildAddress = (address: AddressDto) => {
  const number = [address.streetNumber, address.orientationNumber]
    .filter(Boolean)
    .join("/");

  const firstPart = [address.street, number];
  const secondPart = [
    `${address.postalCode.slice(0, 3)} ${address.postalCode.slice(3)}`,
    address.municipality,
  ];

  return [firstPart.join(" "), secondPart.join(" ")].join(", ");
};
