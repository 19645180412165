import { Control } from "react-hook-form";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { useAddressTypeList } from "Hooks/Options/useAddressTypeList";
import { AddressDto } from "Infrastructure/Api/Api";
import { FormSection } from "Components/Users/UserDetailPageContent/Components/FormSection";

type AddressFieldsProps = {
  addresses: Array<AddressDto>;
  control: Control<any>;
};

export const AddressFields = ({ addresses, control }: AddressFieldsProps) => {
  const addressTypeList = useAddressTypeList();

  return (
    <>
      {addresses.map(({ addressID }, index) => {
        const prefix = `addresses.${index}.`;
        const title = addresses.length > 1 ? `${index + 1}. Adresa` : "Adresa";

        return (
          <FormSection legend={title} key={addressID || index}>
            <Dropdown
              control={control}
              name={`${prefix}type`}
              label="Typ adresy"
              codeList={addressTypeList}
            />
            <FormInput
              control={control}
              name={`${prefix}street`}
              label="Ulice"
            />
            <FormInput
              control={control}
              name={`${prefix}streetNumber`}
              label="Číslo popisné"
            />
            <FormInput
              control={control}
              name={`${prefix}orientationNumber`}
              label="Číslo orientační"
            />
            <FormInput
              control={control}
              name={`${prefix}postalCode`}
              label="PSČ"
            />
            <FormInput
              control={control}
              name={`${prefix}municipality`}
              label="Obec"
            />
          </FormSection>
        );
      })}
    </>
  );
};
