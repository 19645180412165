import { FormFields } from "Components/Merchant/Initiate/FormFields";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { FormProvider, useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect } from "react";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { useFormValidationSchema } from "Components/Merchant/Initiate/useFormValidationSchema";
import { Skeleton } from "@mui/material";
import { useTransactionInitiate } from "Hooks/useTransactionInitiate";

type Props = {
  publicID?: string;
};

export type FormModel = {
  accountType: "bank" | "iban";
  bankAccountID?: number;
  publicID: string;
  iban?: string;
  amount: number;
};

const PageResources = Resources.Merchant.Initiate;

export const MerchantInitiateComponent: FC<Props> = ({ publicID }) => {
  const { t } = useResource();
  const initiateMerchantSchema = useFormValidationSchema();

  const { data: accounts, isLoading: isLoadingAccounts } = useBankingAccounts();

  const form = useForm<FormModel>({
    resolver: zodResolver(initiateMerchantSchema),
    defaultValues: {
      accountType: "bank",
      publicID,
    },
  });
  const { handleSubmit, getValues, setValue } = form;
  const { mutate: initiateTransaction, isPending } = useTransactionInitiate();

  useEffect(() => {
    if (!getValues()?.bankAccountID && !isLoadingAccounts) {
      const bankAccountID = accounts?.items?.find(
        x => x.publicID === publicID,
      )?.bankAccountID;

      if (bankAccountID) {
        setValue("bankAccountID", bankAccountID);
      }
    }
  }, [publicID, accounts, getValues, setValue, isLoadingAccounts]);

  const onSubmit = async (data: FormModel) => {
    if (data.amount) {
      initiateTransaction({
        ...(data.accountType === "bank"
          ? { receiverBankAccountID: data.bankAccountID }
          : { receiverIBAN: data.iban?.replace(/\s/g, "") }),
        amount: data.amount,
      });
    }
  };

  return (
    <PrimaryPaper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <FormFields
            bankingAccounts={accounts?.items}
            isLoading={isLoadingAccounts}
          />
        </FormProvider>
        {isLoadingAccounts && !accounts ? (
          <Skeleton variant="rectangular" height={58} sx={{ marginTop: 2 }} />
        ) : (
          <PrimaryButton
            fullWidth
            type="submit"
            color="primary"
            disabled={isPending}
            sx={{ marginTop: 2 }}
          >
            {t(PageResources.GenerateButton)}
          </PrimaryButton>
        )}
      </form>
    </PrimaryPaper>
  );
};
