import { Box } from "@mui/material";
import { PaymentAction } from "./PaymentAction";
import { PaymentActionsProps } from "Components/Dashboard/BalanceWithPaymentActions/types";

export const PaymentActions = ({
  isDisabled,
  payMeAction,
  sendPaymentAction,
}: PaymentActionsProps) => (
  <Box
    display="flex"
    justifyContent="space-around"
    maxWidth="300px"
    width="100%"
    gap={18}
  >
    <PaymentAction
      label={payMeAction.text}
      to={payMeAction.link}
      isDisabled={isDisabled}
    />
    <PaymentAction
      isIconFlipped
      label={sendPaymentAction.text}
      to={sendPaymentAction.link}
      isDisabled={isDisabled}
    />
  </Box>
);
