import { zodResolver } from "@hookform/resolvers/zod";
import { Divider, Typography } from "@mui/material";
import { CountrySelector } from "Components/Onboarding/BusinessEntity/Components/CountrySelector/CountrySelector";
import { LicensePicker } from "Components/Onboarding/BusinessEntity/Components/LicensePicker/LicensePicker";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import {
  LicenseSelectionModel,
  LicenseTier,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { ValuesBox } from "Components/Shared/ValuesBox/ValuesBox";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";

const DEFAULT_LICENSE: LicenseTier = "gold";

const schema = z
  .object({
    license: z.enum(["gold", "silver", "bronze", "basic"]),
    country: z.enum(["CZ", "SK"]),
    isUsingPrivateWallet: z.boolean().optional(),
    privateWalletAddress: z.string().optional(),
  })
  .refine(
    data => {
      // If isUsingPrivateWallet is checked, privateWalletAddress must not be empty
      return (
        !data.isUsingPrivateWallet ||
        (data.privateWalletAddress &&
          data.privateWalletAddress.trim().length > 0)
      );
    },
    {
      message: "U vlastní peněženky musíte vyplnit její adresu.",
      path: ["privateWalletAddress"], // Path to the field with the error
    },
  );

export const LicenseSelectionStep = () => {
  const { t } = useResource();
  const { getFormValues, nextStep } = useOnboardingContext();
  const [selectedLicense, setSelectedLicense] =
    useState<LicenseTier>(DEFAULT_LICENSE);

  const { control, handleSubmit, watch } = useForm<LicenseSelectionModel>({
    defaultValues: {
      ...getFormValues(OnboardingSteps.LicenseSelection),
      license: DEFAULT_LICENSE, //TODO: probably should come from backend
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = (data: LicenseSelectionModel) => {
    nextStep({ data: { ...data, licenseTier: selectedLicense } });
  };

  const isUsingPrivateWallet = watch("isUsingPrivateWallet");

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox submitButtonLabel={t(Resources.Common.Continue)}>
        Koupí licence získáváte právo stát se hunterem pro Legi.one.
        <ValuesBox title={"Země"} withTopMargin>
          <CountrySelector control={control} />
        </ValuesBox>
        <ValuesBox title={"Licence"}>
          <Typography>
            {"Vyberte verzi licence, kterou si chcete zakoupit."}
          </Typography>
          <LicensePicker
            onSelect={setSelectedLicense}
            defaultValue={selectedLicense}
            currentValue={selectedLicense}
          />
        </ValuesBox>
        <Divider sx={{ mt: "1rem" }} />
        <ValuesBox>
          <CheckboxInput
            label={"Chci poslat do vlastní peněženky"}
            control={control}
            name="isUsingPrivateWallet"
          />
          {isUsingPrivateWallet && (
            <FormInput
              control={control}
              name="privateWalletAddress"
              label="Adresa peněženky"
              type="text"
            />
          )}
        </ValuesBox>
        <Divider sx={{ mt: "1rem" }} />
      </StepBox>
    </FullHeightForm>
  );
};
