import { usePartySearchAresMutation } from "Api/Mutations/Party/usePartySearchAresMutation";
import { PartyDto, PartyType } from "Infrastructure/Api/Api";
import { useEffect, useRef } from "react";

type Props = {
  initialIdentifierNumber?: string;
  identifierNumber?: string | number;
  partyType?: PartyType;
  onAresData: (aresData: PartyDto | null) => void;
  disabled?: boolean;
};

export const useAres = ({
  initialIdentifierNumber,
  identifierNumber,
  partyType,
  onAresData,
  disabled,
}: Props) => {
  const lastAres = useRef<string | undefined>(initialIdentifierNumber);
  const { mutate: getAres, isPending: isFetchingAres } =
    usePartySearchAresMutation({ onSuccess: onAresData });

  useEffect(() => {
    const number = String(identifierNumber);

    if (
      !disabled &&
      identifierNumber &&
      number.length === 8 &&
      !isFetchingAres &&
      lastAres.current !== number
    ) {
      getAres({ partyType, query: String(identifierNumber) });

      lastAres.current = number;
    }
  }, [getAres, identifierNumber, isFetchingAres, partyType, disabled]);

  return {
    isFetchingAres,
  };
};
