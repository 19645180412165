import { AddressType } from "Components/Onboarding/types";
import { z } from "zod";

export const addressValidationSchema: z.ZodType<AddressType> = z.object({
  addressID: z.number().optional(),
  street: z.string(),
  descriptionNumber: z.string(),
  orientationNumber: z.string().optional().nullable(),
  city: z.string(),
  postalCode: z.string(),
});
