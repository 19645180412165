import { Bank } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import styled from "styled-components";
import { ImageWithTitle } from "Components/Onboarding/BusinessEntity/Components/BanksList/BankButton/Components/ImageWithTitle";
import { IconSection } from "Components/Onboarding/BusinessEntity/Components/BanksList/BankButton/Components/IconSection";

const StyledButton = styled("button")`
  all: unset;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  border-radius: 10px;
  overflow: hidden;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? "inherit" : theme.palette.action.hover};
  }
  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const BankNameResources = Resources.BankNames;

type Props = {
  bank: Bank | "Unknown";
  isDisabled?: boolean;
  customName?: string;
  isSandbox?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
};

export const BankButton = ({
  isDisabled,
  bank,
  isSandbox,
  customName,
  onClick,
  isLoading,
}: Props) => {
  const { t } = useResource();

  const isButtonDisabled = isDisabled || isLoading;
  const bankName = customName || t(BankNameResources[bank]);

  return (
    <StyledButton onClick={onClick} disabled={isButtonDisabled} type="button">
      <ImageWithTitle
        bank={bank}
        title={bankName}
        isDisabled={isDisabled}
        isSandbox={isSandbox}
      />
      <IconSection isDisabled={isDisabled} isLoading={isLoading} />
    </StyledButton>
  );
};
