import { FormItemsBox } from "Components/Shared/FormItemsBox";
import { BlFileInputForm } from "Components/Shared/Inputs/Form/BlFileInputForm";
import { ValuesBox } from "Components/Shared/ValuesBox/ValuesBox";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";

type Props<FormType extends FieldValues> = {
  control: Control<FormType, object>;
  frontSideName: FieldPath<FormType>;
  backSideName: FieldPath<FormType>;
};

const StepResources = Resources.Onboarding.Steps.Documents;

export const IdentityCardForms = <FormType extends FieldValues>({
  control,
  backSideName,
  frontSideName,
}: Props<FormType>) => {
  const { t } = useResource();

  return (
    <ValuesBox title={t(StepResources.Forms.DocumentTitle)}>
      <FormItemsBox>
        <BlFileInputForm
          control={control}
          label={t(StepResources.Forms.FrontSide)}
          name={frontSideName}
          title={t(StepResources.Forms.DocumentTitle)}
          description={t(StepResources.Forms.DocumentDescription)}
        />
        <BlFileInputForm
          control={control}
          label={t(StepResources.Forms.BackSide)}
          name={backSideName}
          title={t(StepResources.Forms.DocumentTitle)}
          description={t(StepResources.Forms.DocumentDescription)}
        />
      </FormItemsBox>
    </ValuesBox>
  );
};
