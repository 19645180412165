import { Box } from "@mui/material";
import { getAppUrl } from "Utils/UrlUtils";
import { PaymentButton } from "./PaymentButton";

type PaymentButtonsProps = {
  firstAccountPublicID?: string;
};

export const PaymentButtons = ({
  firstAccountPublicID,
}: PaymentButtonsProps) => {
  const appUrl = getAppUrl();

  return (
    <Box
      display="flex"
      justifyContent="space-around"
      width="100%"
      maxWidth="300px"
      gap={18}
    >
      <PaymentButton
        to={`${appUrl(
          "customer-payment-pay",
        )}?publicID=${firstAccountPublicID}`}
        label="Platba"
      />
      <PaymentButton
        to={`${appUrl("initiate-payment")}?publicID=${firstAccountPublicID}`}
        label="Zaplať mi"
        isIconFlipped
      />
    </Box>
  );
};
