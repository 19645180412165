import { Typography } from "@mui/material";
import { BankSelectorComponent } from "Components/Banking/Auth/BankSelectorComponent";
import { useBankingAuth } from "Hooks/useBankingAuth";
import { BankEnvironment } from "Infrastructure/Api/Api";
import { FC } from "react";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  transactionPublicID: string;
};

const ComponentResources = Resources.Customer.Pay;

export const BankSelector: FC<Props> = ({ transactionPublicID }) => {
  const { t } = useResource();
  const { isPending, authorize, selectedEnvironment } = useBankingAuth();

  const onBankSelected = (environment: BankEnvironment) => {
    if (!isPending) {
      authorize({ environment, transactionPublicID });
    }
  };

  return (
    <div>
      <Typography marginBottom={4}>
        {t(ComponentResources.ChooseBank)}
      </Typography>
      <BankSelectorComponent
        isActionPending={false}
        onBankSelected={onBankSelected}
        selectedEnvironment={selectedEnvironment}
      />
    </div>
  );
};
