import { useMutation } from "@tanstack/react-query";
import {
  putPartyPublicIDRelation,
  RelatedPartyDto,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: () => void;
};

export const useReplacePartyRelationMutation = ({ onSuccess }: Props = {}) =>
  useMutation({
    mutationFn: processResponse(
      ({
        data,
        partyPublicID,
      }: {
        data: RelatedPartyDto;
        partyPublicID: string;
      }) => putPartyPublicIDRelation(data, partyPublicID),
    ),
    onSuccess: () => {
      onSuccess?.();
    },
  });
