import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";

export const DesktopBalanceSkeleton = () => (
  <Box>
    <Skeleton variant="text" width="50%" height={50} />
    <Box display="flex" gap={4} mt={4}>
      <Skeleton variant="rounded" width="100%" height={53} />
      <Skeleton variant="rounded" width="100%" height={53} />
    </Box>
  </Box>
);
