import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { useDeletePartyMutation } from "Api/Mutations/Party/useDeletePartyMutation";
import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { useRelatedParty } from "Components/Onboarding/BusinessEntity/Hooks/useRelatedParty";
import { OwnerType } from "Components/Onboarding/BusinessEntity/types";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { ValuesBox } from "Components/Onboarding/NaturalPerson/Components/ValuesBox";
import { addressValidationSchema } from "Components/Onboarding/schemas";
import {
  getAddressDtofromAddressType,
  getAddressTypeFromAddressDto,
} from "Components/Onboarding/utils";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CountryDropdown } from "Components/Shared/Inputs/Form/Dropdowns/CountryDropdown";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { endOfDay } from "date-fns";
import { AddressType, PartyType, RelationType } from "Infrastructure/Api/Api";
import { FC, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";

const StepResources = Resources.Onboarding.Steps.PersonalInformation;

type Props = {
  publicID?: string;
  onSave: (publicID: string) => void;
  onDelete?: (publicID: string) => void;
};

const schemaValidation: z.ZodType<OwnerType> = z.object({
  firstName: z.string(),
  lastName: z.string(),
  birthDate: z.coerce.date(),
  nationality: z.number(),
  address: addressValidationSchema,
});

export const EditValues: FC<Props> = ({ onSave, publicID, onDelete }) => {
  const partyPublicID = useRef(publicID);
  const { t } = useResource();

  const { handleSubmit, control, reset, getValues } = useForm<OwnerType>({
    resolver: zodResolver(schemaValidation),
  });

  const { data: party, isLoading } = usePartyQuery(publicID);
  const { mutate: updateParty, isPending: isUpdatingParty } = usePartyMutation({
    // we are only updating if we have publicID
    onSuccess: () => onSave(partyPublicID.current as string),
  });
  const { mutate: deleteParty, isPending: isDeletingParty } =
    useDeletePartyMutation({
      // we are only deleting if we have publicID
      onSuccess: () => onDelete?.(publicID as string),
    });

  const { isPending, updateRelatedParty } = useRelatedParty({
    onSuccessUpdate: ({ partyPublicID: publicID }) => {
      partyPublicID.current = publicID;
      saveParty();
    },
    partyPublicID: publicID,
  });

  useEffect(() => {
    if (publicID && party) {
      reset({
        address: getAddressTypeFromAddressDto(
          party?.addresses?.find(a => a.type === AddressType.Permanent),
        ),
        birthDate: party.birthDate
          ? endOfDay(new Date(party.birthDate)) // if we receive date with 00:00:00, the calendar will show the previous day
          : undefined,
        firstName: party.firstName ?? undefined,
        lastName: party.lastName ?? undefined,
        nationality: party.nationalityCountryID ?? undefined,
      });
    }
  }, [party, reset, publicID]);

  const saveParty = () => {
    const data = getValues();

    const permanentAddress = party?.addresses?.find(
      ({ type }) => type === AddressType.Permanent,
    );

    const otherAddresses = party?.addresses?.filter(
      ({ type }) => type !== AddressType.Permanent,
    );

    updateParty({
      publicID: partyPublicID.current!,
      data: {
        ...(party ?? {}),
        firstName: data.firstName,
        lastName: data.lastName,
        birthDate: new Date(data.birthDate).toISOString(),
        nationalityCountryID: data.nationality,
        type: PartyType.NaturalPerson,
        gender: party?.gender!,
        isForeigner: party?.isForeigner!,
        relatedParties: party?.relatedParties!,
        addresses: [
          {
            addressID: permanentAddress?.addressID,
            ...getAddressDtofromAddressType(data.address),
          },
          ...(otherAddresses ?? []),
        ],
      },
    });
  };

  const onSubmit = async () => {
    if (publicID === undefined) {
      await updateRelatedParty({
        relationType: RelationType.BeneficialOwner,
        publicID,
      });
    } else {
      saveParty();
    }
  };

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        submitButtonLabel="Uložit"
        isSubmitting={
          isPending || isLoading || isUpdatingParty || isDeletingParty
        }
      >
        <ValuesBox title="Základní údaje" hideDivider>
          <FormInput
            control={control}
            name="firstName"
            label={t(Resources.Common.FirstName)}
            required
          />
          <FormInput
            control={control}
            name="lastName"
            label={t(Resources.Common.LastName)}
            required
          />
          <FormInput
            type="date"
            control={control}
            name="birthDate"
            label={t(Resources.Common.BirthDate)}
            required
          />
          <CountryDropdown
            control={control}
            name="nationality"
            label={t(Resources.Common.Nationality)}
          />
        </ValuesBox>
        <ValuesBox title="Adresa" hideDivider>
          <FormInput
            control={control}
            name="address.street"
            label={t(StepResources.StreetAddress)}
            required
          />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormInput
                control={control}
                name="address.descriptionNumber"
                label={t(Resources.Common.DescriptionNumber)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                control={control}
                name="address.orientationNumber"
                label={t(Resources.Common.OrientationNumber)}
              />
            </Grid>
          </Grid>
          <FormInput
            control={control}
            name="address.city"
            label={t(Resources.Common.City)}
            required
          />
          <FormInput
            control={control}
            name="address.postalCode"
            label={t(Resources.Common.PostalCode)}
            mask="000 00"
            required
          />
        </ValuesBox>
      </StepBox>
      {!!onDelete && publicID && (
        <Box marginTop={3}>
          <PrimaryButton
            onClick={() => deleteParty(publicID)}
            color="error"
            variant="outlined"
            fullWidth
            isLoading={isDeletingParty}
          >
            {t(Resources.Common.Delete)}
          </PrimaryButton>
        </Box>
      )}
    </FullHeightForm>
  );
};
