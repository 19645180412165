import * as React from "react";

export const ForwardArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
    color="currentColor"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.65308 13.6668C2.52297 13.6632 2.39938 13.6091 2.30857 13.5158C2.21776 13.4226 2.16688 13.2976 2.16675 13.1675C2.16675 13.1312 2.17746 13.1051 2.17782 13.0691H2.17651C2.17701 13.0667 2.17862 13.0651 2.17912 13.0626C2.21348 9.79103 3.69342 7.80981 5.35815 6.73191C6.82461 5.78239 8.28101 5.52807 9.16675 5.42917V2.83412C9.16679 2.73525 9.19613 2.63862 9.25108 2.55642C9.30602 2.47423 9.3841 2.41016 9.47545 2.37233C9.56679 2.33449 9.6673 2.32459 9.76427 2.34386C9.86125 2.36313 9.95033 2.41072 10.0203 2.48061L14.6869 7.14727C14.7807 7.24104 14.8333 7.3682 14.8333 7.50079C14.8333 7.63337 14.7807 7.76053 14.6869 7.8543L10.0203 12.521C9.95033 12.5909 9.86125 12.6384 9.76427 12.6577C9.6673 12.677 9.56679 12.6671 9.47545 12.6292C9.3841 12.5914 9.30602 12.5274 9.25108 12.4452C9.19613 12.363 9.16679 12.2663 9.16675 12.1675V9.61928C8.43755 9.58075 7.30771 9.58087 6.15373 9.96042C4.78647 10.4101 3.55276 11.287 3.15698 13.2658C3.1337 13.3813 3.07038 13.4848 2.97819 13.5582C2.88599 13.6316 2.77086 13.67 2.65308 13.6668ZM10.1667 10.9604L13.6264 7.50079L10.1667 4.04115V5.84975C10.1668 5.97594 10.119 6.09747 10.0332 6.18996C9.94735 6.28246 9.8297 6.33907 9.70386 6.34844C9.03566 6.39779 7.35977 6.62706 5.90177 7.5711C5.0154 8.14502 4.23561 9.01492 3.72925 10.2527C4.36189 9.66556 5.09899 9.25468 5.84123 9.01055C7.42997 8.488 9.01897 8.56596 9.71362 8.631C9.83761 8.64268 9.95277 8.70021 10.0366 8.79233C10.1204 8.88444 10.1668 9.00451 10.1667 9.12904V10.9604Z"
    />
  </svg>
);
