import { Box, DialogTitle, DialogTitleProps, Stack } from "@mui/material";
import { CloseIcon } from "Components/Shared/Icons";

type Props = DialogTitleProps & {
  onClose?: () => void;
};

export const BlDialogTitle: React.FC<Props> = ({
  onClose,
  children,
  ...props
}) => {
  if (!onClose) {
    return <DialogTitle {...props} children={children} />;
  }

  return (
    <DialogTitle {...props}>
      <Stack direction="row" alignItems="start" justifyContent="space-between">
        <div>{children}</div>
        <Box
          role="button"
          sx={{ cursor: "pointer" }}
          marginLeft={2}
          paddingTop="1px"
          onClick={onClose}
        >
          <CloseIcon width={18} height="auto" />
        </Box>
      </Stack>
    </DialogTitle>
  );
};
