import { RelationType } from "Infrastructure/Api/Api";
import { CodeListItem } from "Models/ICodeListDto";
import { useMemo } from "react";
import { Resources, useResource } from "Translations/Resources";

type ListItem = CodeListItem<RelationType>;
type Result = ListItem[];

type Props = {
  excludeBeneficialOwner?: boolean;
};

export const useRelationTypeValueList = ({
  excludeBeneficialOwner,
}: Props = {}) => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      Object.entries(RelationType)
        .map<ListItem>(([key]) => ({
          code: key as RelationType,
          name: t(Resources.Form.RelationType[key as RelationType]),
        }))
        .filter(
          item =>
            !excludeBeneficialOwner ||
            item.code !== RelationType.BeneficialOwner,
        ),
    [t, excludeBeneficialOwner],
  );
};
