import { AdditionalInfoStatus } from "Infrastructure/Api/Api";
import { CodeListItem } from "Models/ICodeListDto";
import { useMemo } from "react";
import { Resources, useResource } from "Translations/Resources";

type ListItem = CodeListItem<AdditionalInfoStatus>;
type Result = ListItem[];

export const useAdditionalInfoStatusList = () => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      Object.entries(AdditionalInfoStatus).map<ListItem>(([key]) => ({
        code: key as AdditionalInfoStatus,
        name: t(Resources.Form.AdditionalInfo[key as AdditionalInfoStatus]),
      })),
    [t],
  );
};