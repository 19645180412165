import { useBankingAuthQuery } from "Api/Mutations/useBankingAuth";

export const useBankingAuth = () => {
  const {
    isPending,
    mutate: authorize,
    variables: selectedEnvironment,
  } = useBankingAuthQuery({
    onAuthorizeUrl: ({ url }) => {
      window.location.href = url;
    },
  });

  return {
    authorize,
    isPending,
    selectedEnvironment: selectedEnvironment?.environment,
  };
};
