import { useState, RefObject } from "react";
import { PointerEvent, TouchEvent } from "react";
import { GAP_WIDTH } from "../constants";
import { CarouselOption } from "Components/Shared/Inputs/CarouselPicker/types";

type UseCarouselManagerProps<T> = {
  options: Readonly<Array<CarouselOption<T>>>;
  defaultValue: T;
  isInfinite?: boolean;
  nextSlideThreshold: number;
  onSelect?: (option: CarouselOption<T>) => void;
  carouselRef: RefObject<HTMLDivElement>;
};

type CarouselManagerReturn = {
  currentIndex: number;
  dragDistance: number;
  hasDragged: boolean;
  trackEventHandlers: {
    onPointerDown: (e: PointerEvent) => void;
    onPointerMove: (e: PointerEvent) => void;
    onPointerUp: () => void;
    onPointerLeave: () => void;
    onTouchStart: (e: TouchEvent) => void;
    onTouchMove: (e: TouchEvent) => void;
    onTouchEnd: () => void;
  };
  updateCurrentIndex: (index: number) => void;
};

export const useCarouselManager = <T extends string>({
  options,
  defaultValue,
  isInfinite = false,
  nextSlideThreshold,
  onSelect,
  carouselRef,
}: UseCarouselManagerProps<T>): CarouselManagerReturn => {
  const [currentIndex, setCurrentIndex] = useState<number>(
    options.findIndex(option => option.value === defaultValue) || 0,
  );
  const [isDragging, setIsDragging] = useState(false);
  const [isSwiping, setIsSwiping] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragDistance, setDragDistance] = useState(0);
  const [hasDragged, setHasDragged] = useState(false);

  const recenterPosition = (index = currentIndex) => {
    if (carouselRef.current) {
      carouselRef.current.style.transition = "transform 0.5s ease";
      carouselRef.current.style.transform = `translateX(calc(-${
        index * 100
      }% - ${GAP_WIDTH * index}px))`;
    }
  };

  const updateCurrentIndex = (nextIndex: number) => {
    setCurrentIndex(nextIndex);
    recenterPosition(nextIndex);
    onSelect?.(options[nextIndex]);
  };

  const handleNext = () => {
    const isLastSlide = currentIndex === options.length - 1;
    const nextSlideIndex = currentIndex + 1;
    const nextSelectedSlideIndex = isLastSlide
      ? isInfinite
        ? 0
        : currentIndex
      : nextSlideIndex;

    if (isLastSlide && !isInfinite) {
      recenterPosition();
    }
    updateCurrentIndex(nextSelectedSlideIndex);
  };

  const handlePrev = () => {
    const isFirstSlide = currentIndex === 0;
    const nextSelectedSlideIndex = isFirstSlide
      ? isInfinite
        ? options.length - 1
        : currentIndex
      : currentIndex - 1;

    if (isFirstSlide && !isInfinite) {
      recenterPosition();
    }
    updateCurrentIndex(nextSelectedSlideIndex);
  };

  // Drag handlers
  const handleDragStart = ({ clientX }: PointerEvent) => {
    setIsDragging(true);
    setDragStartX(clientX);
    setHasDragged(false);
  };

  const handleDragMove = ({ clientX }: PointerEvent) => {
    if (!isDragging || isSwiping) return;

    setDragDistance(clientX - dragStartX);

    if (carouselRef.current) {
      carouselRef.current.style.transition = "none";
      carouselRef.current.style.transform = `translateX(calc(-${
        currentIndex * 100
      }% + ${dragDistance - GAP_WIDTH * currentIndex}px))`;
    }

    if (Math.abs(clientX - dragStartX) > 5) {
      setHasDragged(true);
    }
  };

  const handleDragEnd = () => {
    if (!isDragging || isSwiping) return;
    setIsDragging(false);

    if (dragDistance > nextSlideThreshold) {
      handlePrev();
    } else if (dragDistance < -nextSlideThreshold) {
      handleNext();
    } else {
      recenterPosition();
    }

    setDragDistance(0);
  };

  // Touch handlers
  const handleTouchStart = ({ touches }: TouchEvent) => {
    setIsSwiping(true);
    document.body.style.overflowX = "hidden";
    setDragStartX(touches[0].clientX);
  };

  const handleTouchMove = ({ touches }: TouchEvent) => {
    if (!isSwiping) return;

    const currentX = touches[0].clientX;
    setDragDistance(currentX - dragStartX);

    if (carouselRef.current) {
      carouselRef.current.style.transition = "none";
      carouselRef.current.style.transform = `translateX(calc(-${
        currentIndex * 100
      }% + ${dragDistance - GAP_WIDTH * currentIndex}px))`;
    }
  };

  const handleTouchEnd = () => {
    if (!isSwiping) return;
    document.body.style.overflowX = "";
    setIsSwiping(false);

    if (dragDistance > nextSlideThreshold) {
      handlePrev();
    } else if (dragDistance < -nextSlideThreshold) {
      handleNext();
    } else {
      recenterPosition();
    }

    setDragDistance(0);
  };

  return {
    currentIndex,
    dragDistance,
    hasDragged,
    trackEventHandlers: {
      onPointerDown: handleDragStart,
      onPointerMove: handleDragMove,
      onPointerUp: handleDragEnd,
      onPointerLeave: handleDragEnd,
      onTouchStart: handleTouchStart,
      onTouchMove: handleTouchMove,
      onTouchEnd: handleTouchEnd,
    },
    updateCurrentIndex,
  };
};
