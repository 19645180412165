import { Typography, useTheme } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import {
  StyledCell,
  StyledHeaderCell,
  StyledNoData,
  StyledTableWrapper,
} from "Components/Shared/PrimaryDataTable/PrimaryDataTableStyles";
import { TableSkeleton } from "Components/Shared/PrimaryDataTable/TableSkeleton";
import { usePrevious } from "Hooks/usePrevious";
import * as React from "react";
import { isNoU } from "Utils/ObjectUtils";

type Props<T> = {
  rows: {
    data: T;
  }[];
  rowEntityID: keyof T;
  getCell: (id: TableIDType<T>, data: T) => JSX.Element;
  columns: Readonly<Array<Column<T>>>;
  getHeaderCell?: (column: Column<T>) => JSX.Element;
  getBorderColor?: (data: T) => string;
  onPageChange?: (page: number, rows: number) => void;
  resetTablePage?: boolean | null;
  totalCount?: number;
  isLoading?: boolean;
  onRowClick?: (data: T) => void;
  rowsPerPage?: number;
};

type Column<T> = {
  id: TableIDType<T>;
  label?: string;
  maxWidth?: number;
  fillRow?: boolean;
  justifySelf?: string;
};

export type TableIDType<T> = keyof T | "action";

export const PrimaryDataTable = <T extends object>(props: Props<T>) => {
  const {
    columns,
    getCell,
    rows,
    getHeaderCell,
    rowEntityID,
    getBorderColor,
    onPageChange,
    resetTablePage,
    totalCount,
    isLoading,
    onRowClick,
    rowsPerPage: rowsPerPageProp,
  } = props;
  const theme = useTheme();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageProp ?? 25);

  const [showLoader, setShowLoader] = React.useState(false);
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);

  const wasLoaded = usePrevious(isLoading);
  React.useEffect(() => {
    if (!isLoading && wasLoaded) {
      setIsInitialLoad(false);
    }
  }, [isLoading, wasLoaded]);

  React.useEffect(() => {
    const timeout = setTimeout(
      () => setShowLoader(!!isLoading),
      isInitialLoad ? 0 : 300,
    );
    if (!isLoading) {
      clearTimeout(timeout);
      setShowLoader(false);
    }
    return () => clearTimeout(timeout);
  }, [isLoading, isInitialLoad]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
    onPageChange && onPageChange(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = React.useCallback(
    (rows: number) => {
      setRowsPerPage(rows);
      setPage(0);
      onPageChange && onPageChange(0, rows);
    },
    [onPageChange],
  );

  const prevResetTable = usePrevious(resetTablePage);
  React.useEffect(() => {
    if (!isNoU(prevResetTable)) {
      handleChangeRowsPerPage(rowsPerPage);
    }
  }, [resetTablePage, handleChangeRowsPerPage, rowsPerPage, prevResetTable]);

  const getGridTemplateColumns = () => {
    let result = "";

    columns.forEach(x => {
      const maxWidth = x.maxWidth ? `${x.maxWidth}px` : null;
      result += `minmax(auto, ${x.fillRow ? "1fr" : maxWidth ?? "auto"}) `;
    });

    return result;
  };

  const getRows = () => {
    if (!onPageChange) {
      return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    return rows;
  };

  return (
    <>
      <StyledTableWrapper
        gridTemplateColumns={getGridTemplateColumns()}
        columnsCount={columns.length}
        hasHeader={!!getHeaderCell}
        className={"primary-table"}
      >
        {getHeaderCell && (
          <>
            {columns.map(column => (
              <StyledHeaderCell
                key={`${column.id.toString()}`}
                justifySelf={column.justifySelf}
              >
                {getHeaderCell(column)}
              </StyledHeaderCell>
            ))}
          </>
        )}

        {!showLoader &&
          getRows().map(row => (
            <React.Fragment key={`${row.data[rowEntityID]}`}>
              {columns.map(column => (
                <StyledCell
                  key={`${row.data[rowEntityID]}-${column.id.toString()}`}
                  borderColor={
                    getBorderColor
                      ? getBorderColor(row.data)
                      : theme.colors.gray
                  }
                  justifySelf={column.justifySelf}
                  onClick={() => onRowClick && onRowClick(row.data)}
                >
                  {getCell(column.id, row.data)}
                </StyledCell>
              ))}
            </React.Fragment>
          ))}
      </StyledTableWrapper>

      {!showLoader && getRows().length === 0 && (
        <StyledNoData>
          <Typography>{"No data"}</Typography>
        </StyledNoData>
      )}

      {showLoader && <TableSkeleton rowsCount={rowsPerPage} />}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100, 500, 1000]}
        component="div"
        count={totalCount ?? rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={event =>
          handleChangeRowsPerPage(+event.target.value)
        }
        backIconButtonProps={{ title: "Back" }}
        nextIconButtonProps={{ title: "Next" }}
        labelDisplayedRows={paginationInfo => `${paginationInfo.count}`}
        labelRowsPerPage={"Rows per page"}
      />
    </>
  );
};
