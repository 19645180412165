import { FC } from "react";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { Resources, useResource } from "Translations/Resources";
import { Typography } from "@mui/material";
import { QrCodePayment } from "Components/Shared/Payments/QrCodePayment";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { OnboardingSteps } from "Components/Onboarding/BusinessEntity/types";
import { useVerificationsMutation } from "Api/Mutations/Verifications/useVerificationsMutation";
import { useDispatch } from "react-redux";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";

const StepResources =
  Resources.Onboarding.BusinessOnboarding.Steps.AccountValidation;

export const ManualAccountVerificationStep: FC = () => {
  const { t } = useResource();
  const dispatch = useDispatch();
  const { getFormValues } = useOnboardingContext();
  const { mutate, isPending } = useVerificationsMutation({
    onSuccess: () => {
      dispatch(getUserInfoAsync.request(undefined));
    },
  });

  const businessInformation = getFormValues(
    OnboardingSteps.BusinessInformation,
  );

  return (
    <FullHeightForm>
      <StepBox
        submitButtonLabel="Odesláno"
        onSubmitClick={mutate}
        isSubmiting={isPending}
      >
        <Typography>{t(StepResources.Description)}</Typography>

        <QrCodePayment
          isLoading={false}
          amount={1}
          accountNumber="270210904/2010"
          note={t(StepResources.PaymentNote, {
            name: businessInformation.companyName,
          })}
          paymentDescriptor={businessInformation.identificationNumber}
        />

        <Typography marginTop={6}>
          {t(Resources.Transactions.BankTransferInfo)}
        </Typography>
      </StepBox>
    </FullHeightForm>
  );
};
