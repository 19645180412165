import { ExpectedMonthlyRevenue } from "Infrastructure/Api/Api";
import { CodeListItem } from "Models/ICodeListDto";
import { useMemo } from "react";
import { Resources, useResource } from "Translations/Resources";

type ListItem = CodeListItem<ExpectedMonthlyRevenue>;
type Result = ListItem[];

export const useExpectedMonthlyRevenueList = () => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      Object.entries(ExpectedMonthlyRevenue).map<ListItem>(([key]) => ({
        code: key as ExpectedMonthlyRevenue,
        name: t(
          Resources.Form.ExpectedMonthlyRevenue[key as ExpectedMonthlyRevenue],
        ),
      })),
    [t],
  );
};
