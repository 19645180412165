import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { MerchantPaymentComponent } from "Components/Merchant/Payment/MerchantPaymentComponent";
import { TransactionsComponent } from "Components/Dashboard/TransactionsComponent";
import { useBankingAccountsSync } from "Api/Mutations/Accounts/useBankingAccountsSync";
import { useEffect } from "react";
import { BlDivider } from "Components/Shared/BlDivider";

const MenuResources = Resources.Menu.MainMenuItems;

export const MerchantPaymentPage = () => {
  const { t } = useResource();
  const { mutate: syncAccounts } = useBankingAccountsSync();

  useEffect(() => {
    syncAccounts();
  }, [syncAccounts]);

  return (
    <AuthenticatedLayout title={t(MenuResources.InitiatePayment)}>
      <StyledPageWrapper>
        <div className="left-content">
          <MerchantPaymentComponent />
          <BlDivider />
          <TransactionsComponent />
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default MerchantPaymentPage;
