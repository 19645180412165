import { useMutation } from "@tanstack/react-query";
import {
  putVerifications,
  UserVerificationUpdateDto,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: () => void;
};

export const useUpdateVerificationsMutation = ({ onSuccess }: Props = {}) =>
  useMutation({
    mutationFn: processResponse((requestContract: UserVerificationUpdateDto) =>
      putVerifications(requestContract),
    ),
    onSuccess: () => {
      onSuccess?.();
    },
  });
