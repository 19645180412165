import { Box, Typography, Skeleton } from "@mui/material";
import styled from "styled-components";

type BalanceSkeletonProps = {
  isError?: boolean;
};

const StyledSkeleton = styled(Skeleton)({
  backgroundColor: "rgba(255, 255, 255, 0.1)",
});

export const MobileBalanceSkeleton = ({ isError }: BalanceSkeletonProps) => {
  const animation = isError ? false : "pulse";

  return (
    <Box
      height="232px" //for now setting manually to match height of the real component and prevent layout shift
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="white"
      gap={12}
      pb={6}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="subtitle1" color="primary.contrastText">
          Dostupné prostředky
        </Typography>
        {isError ? (
          <Typography color="error.main">Nepodařilo se načíst</Typography>
        ) : (
          <StyledSkeleton variant="text" width={200} height={48} />
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-around"
        width="100%"
        maxWidth="300px"
        gap={18}
      >
        <PaymentButtonSkeleton animation={animation} />
        <PaymentButtonSkeleton animation={animation} />
      </Box>
    </Box>
  );
};

type PaymentButtonSkeletonProps = {
  animation: "pulse" | false;
};

const PaymentButtonSkeleton = ({ animation }: PaymentButtonSkeletonProps) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <StyledSkeleton
      variant="circular"
      width={64}
      height={64}
      animation={animation}
    />
    <StyledSkeleton
      variant="text"
      width={60}
      height={24}
      animation={animation}
    />
  </Box>
);
