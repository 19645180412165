import { Box } from "@mui/material";
import { CarouselPicker } from "Components/Shared/Inputs/CarouselPicker/CarouselPicker";
import gold from "Assets/Images/Licenses/gold.png";
import silver from "Assets/Images/Licenses/silver.png";
import { LicenseTier } from "Components/Onboarding/BusinessEntity/types";
import { AvailabilityInfo } from "./Components/AvailabilityInfo";
import { Description } from "./Components/Description";
import { Price } from "./Components/Price";

//TODO: get generated from BE types
type MockedLicenceDto = {
  imgSrc: string;
  title: string;
  value: LicenseTier;
  total: number;
  available: number;
  price: number;
};

type LicensePickerProps = {
  onSelect: (license: LicenseTier) => void;
  defaultValue: LicenseTier;
  currentValue: LicenseTier;
};

const LICENSES_MOCKS: ReadonlyArray<MockedLicenceDto> = [
  {
    imgSrc: gold,
    title: "Gold",
    value: "gold",
    total: 123,
    available: 95,
    price: 39990,
  },
  {
    imgSrc: silver,
    title: "Silver",
    value: "silver",
    total: 453,
    available: 242,
    price: 29990,
  },
  {
    imgSrc: gold,
    title: "Bronze",
    value: "bronze",
    total: 283,
    available: 35,
    price: 19990,
  },
  {
    imgSrc: silver,
    title: "Basic",
    value: "basic",
    total: 12563,
    available: 3295,
    price: 9990,
  },
];

const licenses = LICENSES_MOCKS.reduce((acc, license) => {
  acc[license.value] = license;
  return acc;
}, {} as Record<LicenseTier, MockedLicenceDto>);

export const LicensePicker = ({
  onSelect,
  defaultValue,
  currentValue,
}: LicensePickerProps) => (
  <>
    <CarouselPicker
      options={LICENSES_MOCKS}
      onSelect={({ value }) => onSelect(value)}
      defaultValue={defaultValue}
    />
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      gap="1rem"
    >
      <Description licenseTier={currentValue} />
      <AvailabilityInfo
        current={licenses[currentValue].available}
        max={licenses[currentValue].total}
      />
      <Price amount={licenses[currentValue].price} />
    </Box>
  </>
);
