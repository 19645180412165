import { Skeleton } from "@mui/material";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { useAdminUserDataQuery } from "Api/Queries/User/useAdminUserDataQuery";
import { useVerificationsQuery } from "Api/Queries/Verifications/useVerificationsQuery";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { UserDetailPageContent } from "Components/Users/UserDetailPageContent/UserDetailPageContent";
import { useParams } from "react-router-dom";
import { getAppUrl } from "Utils/UrlUtils";

const UserDetailPage = () => {
  const appUrl = getAppUrl();
  const { id } = useParams();

  const { data: userDetailData, isLoading: isUserDetailDataLoading } =
    useAdminUserDataQuery(Number(id));

  const { data: verificationData, isLoading: isVerificationLoading } =
    useVerificationsQuery(Number(id) || undefined);

  const { data: partyData, isLoading: isPartyLoading } = usePartyQuery(
    userDetailData?.partyPublicID,
  );

  const isAllDataReady = userDetailData && partyData && verificationData;
  const isLoading =
    isUserDetailDataLoading || isVerificationLoading || isPartyLoading;

  if (isLoading) {
    return (
      <AuthenticatedLayout title="Uživatel" goBackUrl={appUrl("user-list")}>
        <StyledPageWrapper>
          <div className="left-content">
            <Skeleton height={48} variant="rounded" sx={{ mt: "0.25rem" }} />
            <Skeleton height={800} variant="rounded" sx={{ mt: "0.5rem" }} />
          </div>
        </StyledPageWrapper>
      </AuthenticatedLayout>
    );
  }

  if (isAllDataReady) {
    return (
      <AuthenticatedLayout
        title={userDetailData.login}
        goBackUrl={appUrl("user-list")}
      >
        <StyledPageWrapper>
          <div className="left-content">
            <UserDetailPageContent
              user={userDetailData}
              verification={{
                ...verificationData,
                customUserID: verificationData.userID,
              }}
              party={partyData}
            />
          </div>
        </StyledPageWrapper>
      </AuthenticatedLayout>
    );
  }

  return (
    <AuthenticatedLayout title="Chyba" goBackUrl={appUrl("user-list")}>
      Omlouváme se, něco se pokazilo.
    </AuthenticatedLayout>
  );
};

export default UserDetailPage;
