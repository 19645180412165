import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { TransactionListTable } from "Components/Transactions/TransactionListTable";

export const TransactionsPage = () => (
  <AuthenticatedLayout title="Historie">
    <StyledPageWrapper>
      <div className="left-content">
        <TransactionListTable
          limit={7}
          isShowMoreShown
          //isExportShown={true}
        />
      </div>
    </StyledPageWrapper>
  </AuthenticatedLayout>
);

export default TransactionsPage;
