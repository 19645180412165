import { Box, Typography } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { ForwardIcon } from "Components/Shared/Icons";

type PaymentButtonProps = {
  to: string;
  label: string;
  isIconFlipped?: boolean;
};

export const PaymentButton = ({
  to,
  label,
  isIconFlipped = false,
}: PaymentButtonProps) => (
  <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
    <UnstyledLink to={to}>
      <Typography
        color="primary.contrastText"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          borderRadius: "50%",
          width: 64,
          height: 64,
          transform: isIconFlipped ? "scaleX(-1)" : "none",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
        }}
      >
        <ForwardIcon />
      </Typography>
    </UnstyledLink>
    <Typography variant="caption">{label}</Typography>
  </Box>
);
